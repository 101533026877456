import { Col, Row } from 'antd'

const Deck = () => {
    return (
        <Row style={{padding: '25px 20px', width:'100%'}}>
            <Col span={24}>
                DECK
            </Col>
            <Col span={24}>
                <iframe
                    width="100%"
                    height="900"
                    src="https://app.powerbi.com/view?r=eyJrIjoiYjE1ZWQxOWEtNzY3Yy00ZTc0LTlhYjUtZTUzZmI1NTY2MWEzIiwidCI6IjFkZjQ2ODhjLWI5MTUtNDEwMy05OGMwLTNhMzY4ZmIyOTNlOCJ9"
                />
            </Col>
        </Row>
    )
}

export default Deck
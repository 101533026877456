import { createSlice } from "@reduxjs/toolkit"

export const User = createSlice({
    name : 'user',
    initialState : {
        rex_data_user: null,
    },
    reducers : {
        storeDataUser: (state, action) => {
            state.rex_data_user = action.payload
        },
    }
})

export default User.reducer
export const { storeDataUser } = User.actions
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './Redux/Store/index'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from "antd";
import App from './App.jsx'
import './index.css'
import es_ES from "antd/lib/locale/es_ES";

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')).render(
	<ConfigProvider
		locale={es_ES}
	>
		<Provider store={store} >
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</QueryClientProvider>
		</Provider>
	</ConfigProvider>
)

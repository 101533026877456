import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd'
import React from 'react'

const ModalNewProduct = ({
    openModal=false,
    setOpenModal=()=>{},
    dataNewProduct,
    setDataNewProduct

}) => {
	
    const onFinishForm = async (values) => {
        values = {
            ...values,
            sku : dataNewProduct.masterdataproducts.mdpmaterialnumber,
            dateNewProduct : values.dateNewProduct.format('YYYY-MM')
        }

        // await createTransitionProduct.mutate(values)
        setOpenModal(false)
    }

	return (
		<Modal
            title={<div className='ModalNewProduct__Title'>Nuevo producto</div>} 
            open={openModal}
            onCancel={() => {
                setDataNewProduct({})
                setOpenModal(false)
            }}
            footer={null}
            width={550}
        >
            <Form
                name="transitionProduct"
                onFinish={onFinishForm}
                layout='vertical'
            >
                <Row gutter={[12]}>
                    <Col span={12}>
                        <Form.Item
                            label='Código(SKU):'
                            name='codeNewProduct'
                            rules={[
                                {
                                  required: true,
                                  message: 'El codigo es requerido',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='Inicio:'
                            name='dateNewProduct'
                            rules={[
                                {
                                  required: true,
                                  message: 'La fecha es requerida',
                                },
                            ]}
                        >
                            <DatePicker
                                picker="month" 
                                placeholder='Seleccionar fecha'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='Descripcion(SKU):'
                            name='descriptionNewProduct'
                            rules={[
                                {
                                  required: true,
                                  message: 'El codigo es requerido',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='GSU/TON:'
                            name='volumeNewProduct'
                            rules={[
                                {
                                  required: true,
                                  message: 'El volumen es requerido',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='ModalTransitionProduct__Actions'>
                        <Button 
                            onClick={() => setOpenModal(false)}
                            danger
                        >
                            Cancelar
                        </Button>
                        <Button
                            htmlType="submit"
                            type='primary'
                        >Guardar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
	)
}

export default ModalNewProduct
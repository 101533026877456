import React from 'react'
import { Button, Col, Form, InputNumber, Modal, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { updateTotalForecastInitial } from '../../Redux/Reducers/Baseline'

const ModalForescast = ({
    openModal=false,
    setOpenModal=()=>{},
}) => {

    const dispatch = useDispatch()

    const onFinishForm = async (values) => {
        dispatch(updateTotalForecastInitial(values))
        setOpenModal(false)
    }

    return (
        <Modal
            title={<div className='ModalNewProduct__Title'>Forecast - Periodos</div>} 
            open={openModal}
            onCancel={() => {
                setOpenModal(false)
            }}
            footer={null}
            width={550}
        >
            <Form
                name="forecastPeriods"
                onFinish={onFinishForm}
                layout='vertical'
            >
                <Row gutter={[12,12]}>
                    <Col span={12}>
                        <Form.Item
                            label='Junio:'
                            name='forecastJun'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                            <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Agosto:'
                            name='forecastAgo'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                           <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Octubre:'
                            name='forecastOct'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                           <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>

                        <Form.Item
                            label='Diciembre:'
                            name='forecastDic'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                           <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label='Julio:'
                            name='forecastJul'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                           <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Septiembre:'
                            name='forecastSep'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                           <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Noviembre:'
                            name='forecastNov'
                            rules={[
                                {
                                  required: true,
                                  message: 'El total es requerido',
                                },
                            ]}
                        >
                           <InputNumber
                                min={1}
                                style={{width:'100%'}}
                            />
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{display:'flex', justifyContent:'end', gap:'10px'}}>
                        <Button danger>Cancelar</Button>
                        <Button 
                            type='primary'
                            htmlType="submit"
                        >Guardar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default ModalForescast
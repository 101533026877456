import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Col, Form, Input, Modal, Row } from 'antd'
import './../../Styles/Components/Baseline/ModalTransitionProduct.css'
import { createNewVersionServices, getFiltersServices } from '../../Redux/Services/Baseline'
import { changeModalNewVersion } from '../../Redux/Reducers/Baseline'

const ModalNewVersion = ({
    rex_data_baseline,
    rex_file_paths,
    rex_data_version_selected,
    rex_filter_data_baseline,
    rex_cambios_pre_version,
    rex_data_new_versions,
}) => {

    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const createNewVersion = useMutation({
        mutationFn: (values) => createNewVersionServices(
            dispatch,
            rex_cambios_pre_version,
            rex_data_new_versions, 
            rex_data_version_selected, 
            values
        )
    })

    const onFinishForm = async (values) => {
        createNewVersion.mutate(values)
    }

    const { 
        rex_modal_new_version,
        rex_create_version_baseline
	} = useSelector(state => state.baseline)

    return (
        <Modal
            title={<div className='ModalTransitionProduct__Title'>Agregar nuevo versionamiento</div>} 
            open={rex_modal_new_version}
            onCancel={() => {
                dispatch(changeModalNewVersion(false))
                // if(createVersion.isPending){
                //     dispatch(changeModalNewVersion(true))
                // }else{
                //     dispatch(changeModalNewVersion(false))
                // }
            }}
            footer={null}
            width={550}
        >
            <Form
                name="transitionProduct"
                onFinish={onFinishForm}
            >
                <Row gutter={[12]}>
                    <Col span={24}>
                        <Form.Item
                            label='Nombre del Versionamiento:'
                            name='nombreversionamiento'
                            rules={[
                                {
                                  required: true,
                                  message: 'El nombre del versionamiento es requerido',
                                },
                            ]}
                        >
                            <Input autoComplete='off' />
                        </Form.Item>
                    </Col>
                    <Col span={24} className='ModalTransitionProduct__Actions'>
                        <Button 
                            onClick={() => dispatch(changeModalNewVersion(false))}
                            danger
                            // disabled={createVersion.isPending ? true : false}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            // loading={createVersion.isPending}
                            htmlType="submit"
                            loading={rex_create_version_baseline}
                            type='primary'
                        >
                            {rex_create_version_baseline ? 'Guardando' : 'Guardar'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default ModalNewVersion
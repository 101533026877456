import { createSlice } from "@reduxjs/toolkit"
import { 
    ChangeFiltersBaselineReducer, 
    GenerateAmountInitialBaselineReducer, 
    GenerateFiltersBaselineReducer, 
    GenerateTotalMonthsBaselineReducer, 
    GenerateTotalNivPricesBaselineReducer, 
    ModifyGsuTonBaselineReducer, 
    UpdateMixBaselineReducer,
    ChangeFiltersDataBaseline
} from "../Actions/Baseline"

export const Baseline = createSlice({
    name : 'baseline',
    initialState : {
        rex_data_baseline                   : [],
        rex_data_columns_baseline           : [],
        rex_total_data_baseline             : null,
        rex_create_version_baseline         : false,
        rex_filter_data_baseline            : {},
        rex_data_metrics                    : [],
        rex_cambios_pre_version             : [],
        rex_page_data_baseline              : 1,
        rex_data_baseline_clone             : [],
        rex_filter_active : false,
        rex_data_filter_selected_baseline            : {
            mdcgba1         : '',
            mdcgba2         : '',
            mdcfcstgroupcode: '',
            mdpsector       : '',
            mdpcategoria    : '',
            mdpsubcategoria : '',
            mdpmarca        : '',
            mdpmetrajetalla : '',
            mdpformato      : '',
            mdpresumen      : ''
        },
        rex_data_filter_baseline   : {},
        rex_data_total_months               : {
            total_gsu_jun : 0,
            total_gsu_jul : 0,
            total_gsu_ago : 0,
            total_gsu_sep : 0,
            total_gsu_oct : 0,
            total_gsu_nov : 0,
            total_gsu_dic : 0,
            
            sub_total_gsu_jun : 0,
            sub_total_gsu_jul : 0,
            sub_total_gsu_ago : 0,
            sub_total_gsu_sep : 0,
            sub_total_gsu_oct : 0,
            sub_total_gsu_nov : 0,
            sub_total_gsu_dic : 0,

            
            total_cases_jun : 0,
            total_cases_jul : 0,
            total_cases_ago : 0,
            total_cases_sep : 0,
            total_cases_oct : 0,
            total_cases_nov : 0,
            total_cases_dic : 0,

            total_nivpx_jun : 0,
            total_nivpx_jul : 0,
            total_nivpx_ago : 0,
            total_nivpx_sep : 0,
            total_nivpx_oct : 0,
            total_nivpx_nov : 0,
            total_nivpx_dic : 0,


            total_nivlc_jun : 0,
            total_nivlc_jul : 0,
            total_nivlc_ago : 0,
            total_nivlc_sep : 0,
            total_nivlc_oct : 0,
            total_nivlc_nov : 0,
            total_nivlc_dic : 0,
            total_netpx_jun : 0,
            total_netpx_jul : 0,
            total_netpx_ago : 0,
            total_netpx_sep : 0,
            total_netpx_oct : 0,
            total_netpx_nov : 0,
            total_netpx_dic : 0,
            total_netlc_jun : 0,
            total_netlc_jul : 0,
            total_netlc_ago : 0,
            total_netlc_sep : 0,
            total_netlc_oct : 0,
            total_netlc_nov : 0,
            total_netlc_dic : 0,
        },
        rex_data_version: [],
        rex_data_version_selected: {},
        rex_data_mdp: [],
        rex_info_status_dashboard : {
            code : "InitialStatus",
            // code : "InitialStatus",
            status : 3,
            description : "Status inicial del Dashboard"
            // code : "Idle",
            // status : 0,
            // description : "El dashboard se encuentra actualizado"
        },
        rex_data_mdc: [],
        rex_data_sku: [],
        rex_data_sector: [],
        rex_data_categoria: [],
        rex_data_marca: [],
        rex_modal_new_version: false,
        rex_file_paths: [],
        rex_data_new_versions: [],
        rex_total_metrics_baseline : {},
        rex_gba1_mdc: [],
        rex_gba2_mdc: [],
        rex_fc_grp_cod_mdc: [],
        rex_sector_mdp: [],
        rex_categoria_mdp: [],
        rex_subcategoria_mdp: [],
        rex_marca_mdp: [],
        rex_metraje_talla_mdp: [],
        rex_formato_mdp: [],
        rex_resumen_mdp: [],
    },
    reducers : {
        storeFiltersDataBaseline: (state, action) => {
            let { value, filter, type } = action.payload
            let data_filter = {...state.rex_filter_data_baseline}

            let { data } = ChangeFiltersDataBaseline( data_filter, value, filter, type )

            state.rex_filter_data_baseline = data
        },
        storeTotalMetricsBaseline: (state, action) => {
            state.rex_total_metrics_baseline = action.payload
        },
        updateStatusCreateVersionBaseline: (state, action) => {
            state.rex_create_version_baseline = action.payload
        },
        storeDataColumnsBaseline: (state, action) => {
            state.rex_data_columns_baseline = action.payload
        },
        storeDataBaseline: (state, action) => {

            let changesPrev     = state.rex_cambios_pre_version

            let metrics    = state.rex_total_metrics_baseline


            let valueTotalMonths = [
                {
                    "month" : "08",
                    "year" : "2024",
                    valueTotal : metrics.sumTotalAgo.privolumen
                },
                {
                    "month" : "09",
                    "year" : "2024",
                    valueTotal : metrics.sumTotalSep.privolumen
                },
                {
                    "month" : "10",
                    "year" : "2024",
                    valueTotal : metrics.sumTotalOct.privolumen
                },
                {
                    "month" : "11",
                    "year" : "2024",
                    valueTotal : metrics.sumTotalNov.privolumen
                },
                {
                    "month" : "12",
                    "year" : "2024",
                    valueTotal : metrics.sumTotalDic.privolumen
                },
                {
                    "month" : "01",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalEne.privolumen
                },
                {
                    "month" : "02",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalFeb.privolumen
                },
                {
                    "month" : "03",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalMar.privolumen
                },
                {
                    "month" : "04",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalAbr.privolumen
                },
                {
                    "month" : "05",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalMay.privolumen
                },
                {
                    "month" : "06",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalJun.privolumen
                },
                {
                    "month" : "07",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalJul.privolumen
                },
                {
                    "month" : "08",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalAgon.privolumen
                },
                {
                    "month" : "09",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalSepn.privolumen
                },
                {
                    "month" : "10",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalOctn.privolumen
                },
                {
                    "month" : "11",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalNovn.privolumen
                },
                {
                    "month" : "11",
                    "year" : "2025",
                    valueTotal : metrics.sumTotalDicn.privolumen
                },
            ]
            
            let valueTotal
            

            if(changesPrev.length > 0){

                let {prevData, metrics}    = action.payload
                

                let iter = 0
                    
                changesPrev.forEach(prev => {

                    let filteredData = []
                    // let valueTotal = 0

                    if(iter == 0){
                        let monthSelected = valueTotalMonths.find(val => val.month == prev.month && val.year == prev.year)
                        valueTotal = monthSelected.valueTotal
                    }


                    if(prev.filters.masterdataproducts && prev.filters.masterdataclients){
                        filteredData = prevData.filter(item =>
                            Object.entries(prev.filters.masterdataproducts).every(([key, value]) =>
                                item.masterdataproducts[key] === value
                            ) &&
                            Object.entries(prev.filters.masterdataclients).every(([key, value]) =>
                                item.masterdataclients[key] === value
                            )
                        )
                    }else if(prev.filters.masterdataclients){
                        filteredData = prevData.filter(item =>
                            Object.entries(prev.filters.masterdataclients).every(([key, value]) =>
                                item.masterdataclients[key] === value
                            )
                        )
                    }else if(prev.filters.masterdataproducts){
                        filteredData = prevData.filter(item =>
                            Object.entries(prev.filters.masterdataproducts).every(([key, value]) =>
                                item.masterdataproducts[key] === value
                            )
                        )
                    }else{
                        filteredData = prevData
                    }

                    let idsFilters = filteredData.map(fil => fil.bslid)

    
                    filteredData.forEach(fid => {
                        fid.prices.map(prc => {
                            if(prc.fecfecha.fecmonth == prev.month && prc.fecfecha.fecyear == prev.year){
                                prc.privolumen = (prev.value/valueTotal)*prc.privolumen
                                prc.cajas = prc.privolumen/fid.bslgsuton
                                prc.prinivlc = prc.cajas*prc.prinivprice

                            }
                        })
                    })

                    valueTotal = prev.value

                    prevData = prevData.filter(item => !idsFilters.includes(item.bslid));
                    prevData = prevData.concat(filteredData)

                    iter = iter + 1
                })

                state.rex_data_baseline = prevData

            }else{
                const { prevData, metrics } = action.payload
                state.rex_data_baseline = prevData
            }
        },
        storeDataBaselineClone: (state, action) => {
            state.rex_data_baseline_clone = action.payload
        },
        storeTotalDataBaseline: (state, action) => {
            state.rex_total_data_baseline = action.payload
        },
        storePageDataBaseline: (state, action) => {
            state.rex_page_data_baseline = action.payload
        },
        storeDataVersion: (state, action) => {
            state.rex_data_version = action.payload
        },
        storeDataMetricsBaseline: (state, action) => {
            state.rex_data_metrics = action.payload
        },
        storeDataVersionSelected: (state, action) => {
            state.rex_data_version_selected = action.payload
        },
        storeDataSku: (state, action) => {
            state.rex_data_sku = action.payload
        },
        storeDataMdp: (state, action) => {
            state.rex_data_mdp = action.payload
        },
        storeDataMdc: (state, action) => {
            state.rex_data_mdc = action.payload
        },
        storeDataSector: (state, action) => {
            state.rex_data_sector = action.payload
        },
        storeDataCategoria: (state, action) => {
            state.rex_data_categoria = action.payload
        },
        storeDataMarca: (state, action) => {
            state.rex_data_marca = action.payload
        },
        storeStatusDashboard: (state, action) => {
            state.rex_info_status_dashboard = action.payload
        },
        storeFiltersBaseline: (state, action) => {
            let data = action.payload
            let dataFilters = GenerateFiltersBaselineReducer(data)
            state.rex_data_filter_baseline = dataFilters
        },
        modifyValueMixBaseline: (state, action) => {
            const { id, value } = action.payload

            let dataBaseline = state.rex_data_baseline
            const dataUpdate = UpdateMixBaselineReducer(dataBaseline, id, value)
            state.rex_data_baseline = dataUpdate        
        },
        changeFilterBaseline: (state, action) => {
            let { value, filter } = action.payload
            let dataBaseline = state.rex_data_baseline_clone
            let dataPrevMonth = state.rex_data_total_months
            let filters_selected = state.rex_data_filter_selected_baseline

            let valueSelect = value ? value : ''
            filters_selected = {
                ...filters_selected,
                [filter] : valueSelect
            }
            let { dataFiltered, filterActive, dataMonths } = ChangeFiltersBaselineReducer(dataBaseline, filters_selected, dataPrevMonth)

            state.rex_data_baseline = dataFiltered

            state.rex_data_filter_selected_baseline = filters_selected

            state.rex_filter_active = filterActive

            state.rex_data_total_months = dataMonths
        },
        updateTotalGSU : (state, action) => {

            const { month, year, value, name } = action.payload

            console.log("action.payload")
            console.log(action.payload)

            // let data_baseline   = state.rex_data_baseline
            // let data_metrics    = state.rex_total_metrics_baseline
            // let changesPrev     = state.rex_cambios_pre_version

            let filters     = state.rex_filter_data_baseline
            let dataMonths  = state.rex_data_columns_baseline
            // console.log("dataMonths")
            // console.log(JSON.parse(JSON.stringify(dataMonths)))

            dataMonths.forEach(dat => {
                if(dat.fecmonth == month && dat.fecyear == year){
                    dat['data'][name] = value
                }
            })

            state.rex_data_columns_baseline = dataMonths

            // console.log("filters")
            // console.log(JSON.parse(JSON.stringify(filters)))
                        
            // let changesReverse = changesPrev.reverse()

            // let lastChanges = null
            
            // if(filters.masterdataproducts && filters.masterdataclients){

            //     lastChanges = changesReverse.find(item =>
            //         Object.entries(filters.masterdataproducts).every(([key, value]) =>
            //             item.filters.masterdataproducts[key] === value
            //         ) &&
            //         Object.entries(filters.masterdataclients).every(([key, value]) =>
            //             item.filters.masterdataclients[key] === value
            //         ) && (item.month == month && item.year == year)
            //     )
            // }else if(filters.masterdataproducts){
            //     lastChanges = changesReverse.find(item =>
            //         Object.entries(filters.masterdataproducts).every(([key, value]) =>
            //             item.filters.masterdataproducts[key] === value
            //         ) && (item.month == month && item.year == year)
            //     )
            // }else if(filters.masterdataclients){
            //     lastChanges = changesReverse.find(item =>
            //         Object.entries(filters.masterdataclients).every(([key, value]) =>
            //             item.filters.masterdataclients[key] === value
            //         ) && (item.month == month && item.year == year)
            //     )
            // }

            // changesPrev.push({
            //     filters: filters,
            //     month : month,
            //     year : year,
            //     value : value
            // })

            // state.rex_cambios_pre_version = changesPrev
                        
            // let valueMonth
            // if(month == '08' && year == '2024'){
            //     valueMonth = data_metrics['sumTotalAgo']['privolumen']
            //     data_metrics['sumTotalAgo']['privolumen'] = value

            // }else if(month == '09' && year == '2024'){
            //     valueMonth = data_metrics['sumTotalSep']['privolumen']
            //     data_metrics['sumTotalSep']['privolumen'] = value
            // }else if(month == '10' && year == '2024'){
            //     valueMonth = data_metrics['sumTotalOct']['privolumen']
            //     data_metrics['sumTotalOct']['privolumen'] = value

            // }else if(month == '11' && year == '2024'){
            //     valueMonth = data_metrics['sumTotalNov']['privolumen']
            //     data_metrics['sumTotalNov']['privolumen'] = value

            // }else if(month == '12' && year == '2024'){
            //     valueMonth = data_metrics['sumTotalDic']['privolumen']
            //     data_metrics['sumTotalDic']['privolumen'] = value

            // }else if(month == '01' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalEne']['privolumen']
            //     data_metrics['sumTotalEne']['privolumen'] = value

            // }else if(month == '02' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalFeb']['privolumen']
            //     data_metrics['sumTotalFeb']['privolumen'] = value

            // }else if(month == '03' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalMar']['privolumen']
            //     data_metrics['sumTotalMar']['privolumen'] = value

            // }else if(month == '04' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalAbr']['privolumen']
            //     data_metrics['sumTotalAbr']['privolumen'] = value

            // }else if(month == '05' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalMay']['privolumen']
            //     data_metrics['sumTotalMay']['privolumen'] = value

            // }else if(month == '06' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalJun']['privolumen']
            //     data_metrics['sumTotalJun']['privolumen'] = value

            // }else if(month == '07' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalJul']['privolumen']
            //     data_metrics['sumTotalJul']['privolumen'] = value

            // }else if(month == '08' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalAgon']['privolumen']
            //     data_metrics['sumTotalAgon']['privolumen'] = value

            // }else if(month == '09' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalSepn']['privolumen']
            //     data_metrics['sumTotalSepn']['privolumen'] = value

            // }else if(month == '10' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalOctn']['privolumen']
            //     data_metrics['sumTotalOctn']['privolumen'] = value

            // }else if(month == '11' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalNovn']['privolumen']
            //     data_metrics['sumTotalNovn']['privolumen'] = value

            // }else if(month == '12' && year == '2025'){
            //     valueMonth = data_metrics['sumTotalDic']['privolumen']
            //     data_metrics['sumTotalDic']['privolumen'] = value
            // }
            // data_baseline.map(bas => (
            //     bas.prices.map(prc => {
            //         if(prc.fecfecha.fecmonth == month && prc.fecfecha.fecyear == year){
            //             if(lastChanges){

            //                 prc.privolumen = (value/lastChanges.value) * prc.privolumen
            //             }else{
            //                 prc.privolumen = (value/valueMonth) * prc.privolumen
            //             }
            //         }
            //     })
            // ))

            // state.rex_data_baseline         = data_baseline
        },
        updateSumTotalForecast : (state, action) => {
            const { month, value } = action.payload

            let filters_selected    = state.rex_data_filter_selected_baseline
            let dataBaseline        = state.rex_data_baseline
            let dataBaselineClone   = state.rex_data_baseline_clone
            let filterActive        = state.rex_filter_active
            let totalMonths        = state.rex_data_total_months


            let { newBaseline, newBaselineClone } = ModifyGsuTonBaselineReducer(filters_selected, dataBaseline, dataBaselineClone, month, value, filterActive, totalMonths)

            let dif = 0
            if(month == "06"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_jun : value,
                        total_gsu_jun : parseFloat(totalMonths.total_gsu_jun) + (value - totalMonths.sub_total_gsu_jun),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_jun : value,
                    }
                }
            }else if(month == "07"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_jul : value,
                        total_gsu_jul : parseFloat(totalMonths.total_gsu_jul) + (value - totalMonths.sub_total_gsu_jul),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_jul : value,
                    }
                }
            }else if(month == "08"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_ago : value,
                        total_gsu_ago : parseFloat(totalMonths.total_gsu_ago) + (value - totalMonths.sub_total_gsu_ago),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_ago : value,
                    }
                }
            }else if(month == "09"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_sep : value,
                        total_gsu_sep : parseFloat(totalMonths.total_gsu_sep) + (value - totalMonths.sub_total_gsu_sep),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_sep : value,
                    }
                }
            }else if(month == "10"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_oct : value,
                        total_gsu_oct : parseFloat(totalMonths.total_gsu_oct) + (value - totalMonths.sub_total_gsu_oct),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_ago : value,
                    }
                }
            }else if(month == "11"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_nov : value,
                        total_gsu_nov : parseFloat(totalMonths.total_gsu_nov) + (value - totalMonths.sub_total_gsu_nov),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_nov : value,
                    }
                }
            }else if(month == "12"){
                if(filterActive){
                    totalMonths = {
                        ...totalMonths,
                        sub_total_gsu_dic : value,
                        total_gsu_dic : parseFloat(totalMonths.total_gsu_nov) + (value - totalMonths.sub_total_gsu_nov),
                    }
                }else{
                    totalMonths = {
                        ...totalMonths,
                        total_gsu_dic : value,
                    }
                }
            }

            state.rex_data_baseline         = newBaseline
            state.rex_data_total_months     = totalMonths
        },
        storeTotalMonthsBaseline: (state, action) => {
            let data = action.payload
            let dataTotalMonths = GenerateTotalMonthsBaselineReducer(data)
            state.rex_data_total_months = dataTotalMonths
        },
        updateTotalForecastInitial: (state, action) => {
            const data_baseline = state.rex_data_baseline
            const { fcJun, fcJul, fcAgo, fcSep, fcOct, fcNov, fcDic, data } = GenerateAmountInitialBaselineReducer(data_baseline, action.payload)

            const rex_data_total_months = state.rex_data_total_months

            state.rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jun : fcJun,
                total_gsu_jul : fcJul,
                total_gsu_ago : fcAgo,
                total_gsu_sep : fcSep,
                total_gsu_oct : fcOct,
                total_gsu_nov : fcNov,
                total_gsu_dic : fcDic,
            }

            state.rex_data_baseline = data
            state.rex_data_baseline_clone = data
        },

        updateTotalGsuJunBaseline: (state, action) => {
            const dataBaseline = state.rex_data_baseline
            const rex_data_total_months = state.rex_data_total_months
            const value = action.payload
            dataBaseline.forEach(dat => {
            });
            state.rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jun : action.value
            }
        },

        updateTotalGsuJulBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jul : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuAgoBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_ago : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuSepBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_sep : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuOctBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_oct : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuNovBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_nov : value
            }
            state.rex_data_total_months = rex_data_total_months
        },
        updateTotalGsuDicBaseline: (state, action) => {
            let { ...rex_data_total_months } = state.rex_data_total_months
            let value = action.payload
            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_dic : value
            }
            state.rex_data_total_months = rex_data_total_months
        },

        updateTotalNivPricesBaseline: (state, action) => {
            let dataBaseline = state.rex_data_baseline_clone
            const dataTotalNivPrice = GenerateTotalNivPricesBaselineReducer(dataBaseline)

            let rex_data_total_months = state.rex_data_total_months

            rex_data_total_months = {
                ...rex_data_total_months,
                total_gsu_jun : dataTotalNivPrice.total_gsu_jun,
                total_gsu_jul : dataTotalNivPrice.total_gsu_jul,
                total_gsu_ago : dataTotalNivPrice.total_gsu_ago,
                total_gsu_sep : dataTotalNivPrice.total_gsu_sep,
                total_gsu_oct : dataTotalNivPrice.total_gsu_oct,
                total_gsu_nov : dataTotalNivPrice.total_gsu_nov,
                total_gsu_dic : dataTotalNivPrice.total_gsu_dic,
            }

            state.rex_data_total_months = rex_data_total_months
        },
        changeModalNewVersion: (state, action) => {
            state.rex_modal_new_version = action.payload
        },
        changeFilePaths: (state, action) => {
            state.rex_file_paths = action.payload
        },
        changeDataNewVersions: (state, action) => {
            state.rex_data_new_versions = action.payload
        },
        changeGba1Mdc: (state, action) => {
            state.rex_gba1_mdc = action.payload
        },
        changeGba2Mdc: (state, action) => {
            state.rex_gba2_mdc = action.payload
        },
        changeFcGrpCodMdc: (state, action) => {
            state.rex_fc_grp_cod_mdc = action.payload
        },
        changeSectorMdp: (state, action) => {
            state.rex_sector_mdp = action.payload
        },
        changeCategoriaMdp: (state, action) => {
            state.rex_categoria_mdp = action.payload
        },
        changeSubcategoriaMdp: (state, action) => {
            state.rex_subcategoria_mdp = action.payload
        },
        changeMarcaMdp: (state, action) => {
            state.rex_marca_mdp = action.payload
        },
        changeMetrajeTallaMdp: (state, action) => {
            state.rex_metraje_talla_mdp = action.payload
        },
        changeFormatoMdp: (state, action) => {
            state.rex_formato_mdp = action.payload
        },
        changeResumenMdp: (state, action) => {
            state.rex_resumen_mdp = action.payload
        },
        changeDataFilterBaseline: (state, action) => {
            state.rex_data_filter_selected_baseline = action.payload
        }
    }
})

export default Baseline.reducer
export const { 
    addBaseline, 
    addNewProducts, 
    storeDataBaseline, 
    storeFiltersBaseline, 
    storeTotalMonthsBaseline,
    updateTotalGsuJunBaseline,
    updateTotalGsuJulBaseline,
    updateTotalGsuAgoBaseline,
    updateTotalGsuSepBaseline,
    updateTotalGsuOctBaseline,
    updateTotalGsuNovBaseline,
    updateTotalGsuDicBaseline,
    changeFilterBaseline,
    storeDataBaselineClone, 
    storeDataVersion,
    storePageDataBaseline,
    storeDataVersionSelected, 
    storeDataMdp,
    storeDataMdc,
    storeDataSku, 
    updateTotalForecastInitial,
    storeDataSector, 
    storeDataCategoria, 
    storeDataMarca,
    updateTotalNivPricesBaseline,
    modifyValueMixBaseline,
    updateSumTotalForecast,
    changeModalNewVersion,
    changeFilePaths,
    storeFiltersDataBaseline,
    storeTotalDataBaseline,
    storeDataMetricsBaseline,
    changeDataNewVersions,
    storeTotalMetricsBaseline,
    updateTotalGSU,
    changeGba1Mdc,
    changeGba2Mdc,
    changeFcGrpCodMdc,
    changeSectorMdp,
    changeCategoriaMdp,
    changeSubcategoriaMdp,
    changeMarcaMdp,
    changeMetrajeTallaMdp,
    changeFormatoMdp,
    changeResumenMdp,
    storeStatusDashboard,
    updateStatusCreateVersionBaseline,
    changeDataFilterBaseline,
    storeDataColumnsBaseline
} = Baseline.actions
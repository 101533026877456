import React, { useState, useRef, useEffect } from 'react'
import { Button, Col, Row } from 'antd'
import ImageRocket from './../../Assets/img/FileUpload/cohete.gif'
import {
	PlusCircleTwoTone,
	CloseCircleTwoTone,
	ToTopOutlined,
	SendOutlined,
	LoadingOutlined,
	ClockCircleOutlined
} from '@ant-design/icons'
import './../../Styles/Components/FileUpload/CardFileUpload.css'

const CardFileUpload = ({
	nameFile,
	onAction,
	isLoading,
	isSuccess,
}) => {

	const [fileNameTag, setFileNameTag] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)

	const [ sendingFile, setSendingFile ] = useState(false)

	const RefFile = useRef(null)

	const imageFileUpload = (image) => {
        if(image){
            setFileUrl([image])
            setFileNameTag(image.name)
        }
    }
	
	const validateFile = async () => {
		if(fileUrl){
			setSendingFile(true)
			await onAction(fileUrl)
			setSendingFile(false)
		}
	}

	const removeFileUpload = () => {
		setFileNameTag(null)
		setFileUrl(null)
	}

	// useEffect(() => {
	// 	setFileNameTag(null)
	// 	setFileUrl(null)
	// },[isSuccess])

	return (
		<Row className='CardFileUpload'>
			<Col span={24} className='CardFileUpload__Info'>
				<img
					src={ImageRocket}
				/>
				<input 
					type="file" 
					ref={RefFile} 
					style={{display: 'none'}} 
					onChange={(e) => {
						imageFileUpload(e.target.files[0], e)
						e.target.value = null
					}} 
				/>
				<div style={{width:'100%', alignItems:'center', textAlign:'center', display:'flex',  justifyContent:'normal', gap:'5px'}}>
					<div>
						{
							isLoading
							?	<ClockCircleOutlined
									style={{fontSize:'20px'}}
									twoToneColor='#1677ff'
								/>
							// ToTopOutlined,
							// SendOutlined
							// LoadingOutlined
							// PlusCircleTwoTone
							:	<PlusCircleTwoTone
									disabled
									style={{
										fontSize:'20px', 
										cursor:'pointer',
									}}
									twoToneColor='#1677ff'
									onClick={() => RefFile.current.click()}
								/>
						}
					</div>
					<div style={{display:'flex', width:'100%', textOverflow:'ellipsis', overflow:'hidden'}}>
						{nameFile}
					</div>
				</div>
			</Col>
			{
				!fileNameTag
				? null
				: fileNameTag
					? <Col span={24}>
						<Row>
							<Col span={20}>
								<div style={{fontSize:'12px', textOverflow:'ellipsis', textWrap:'nowrap', overflow:'hidden'}}>{fileNameTag}</div>						
							</Col>
							<Col span={4} style={{display:'flex', justifyContent:'end'}}>
								<div>
									<CloseCircleTwoTone
										onClick={removeFileUpload}
										style={{
											cursor:'pointer',
											fontSize:'16px'
										}}
										twoToneColor='#ff4d4f'
									/>
								</div>
							</Col>
						</Row>
					</Col>
					: null
			}
			<Col span={24} className='CardFileUpload__Actions'>
				<Button
					type='primary'
					onClick={validateFile}
					className='CardFileUpload__Button'
					disabled={!fileUrl}
					loading={isLoading}
				>
					{isLoading ? 'Subiendo' : 'Subir'}
				</Button>
			</Col>
		</Row>
	)
}

export default CardFileUpload

export const ChangeFiltersBaselineReducer = (data, filters_selected, dataPrevMonth) => {
    
    let dataFiltered = []

    let filterActive = false

    dataFiltered = data.filter(dat => 

        (filters_selected['mdcgba1'] != "" ? dat['masterdataclients']?.['mdcgba1']?.includes(filters_selected['mdcgba1']) : true) 
        && (filters_selected['mdcgba2'] != "" ? dat['masterdataclients']?.['mdcgba2']?.includes(filters_selected['mdcgba2']) : true)
        && (filters_selected['mdcfcstgroupcode'] != "" ? dat['masterdataclients']?.['mdcfcstgroupcode']?.includes(filters_selected['mdcfcstgroupcode']) : true)
        && (filters_selected['mdpsector'] != "" ? dat['masterdataproducts']['mdpsector']?.includes(filters_selected['mdpsector']) : true) 
        && (filters_selected['mdpcategoria'] != "" ? dat['masterdataproducts']['mdpcategoria']?.includes(filters_selected['mdpcategoria']) : true) 
        && (filters_selected['mdpsubcategoria'] != "" ? dat['masterdataproducts']['mdpsubcategoria']?.includes(filters_selected['mdpsubcategoria']) : true) 
        && (filters_selected['mdpmarca'] != "" ? dat['masterdataproducts']['mdpmarca']?.includes(filters_selected['mdpmarca']) : true) 
        && (filters_selected['mdpmetrajetalla'] != "" ? dat['masterdataproducts']['mdpmetrajetalla']?.includes(filters_selected['mdpmetrajetalla']) : true) 
        && (filters_selected['mdpformato'] != "" ? dat['masterdataproducts']['mdpformato']?.includes(filters_selected['mdpformato']) : true) 
        && (filters_selected['mdpresumen'] != "" ? dat['masterdataproducts']['mdpresumen']?.includes(filters_selected['mdpresumen']) : true)
    )

    const checkFilters = [
        'mdcgba1', 'mdcgba2', 'mdcfcstgroupcode', 
        'mdpsector', 'mdpcategoria', 'mdpsubcategoria', 
        'mdpmarca', 'mdpmetrajetalla', 'mdpformato', 
        'mdpresumen'
    ];

    if(checkFilters.some(key => filters_selected[key] !== "")){
        filterActive = true
        
        let dataJun = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "06") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);
    
        let dataJul = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "07") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);
    
        let dataAgo = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "08") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);
    
        let dataSep = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "09") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);
    
        let dataOct = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "10") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);
    
        let dataNov = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "11") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);
    
        let dataDic = dataFiltered.reduce((acc, item) => {
            const sum = item.prices.reduce((innerAcc, price) => {
                if (price.fecfecha.fecmonth == "12") {
                    return innerAcc + parseFloat(price.privolumen);
                }
                return innerAcc;
            }, 0);
            return acc + sum;
        }, 0);

        dataPrevMonth = {
            ...dataPrevMonth,
            sub_total_gsu_jun : dataJun,
            sub_total_gsu_jul : dataJul,
            sub_total_gsu_ago : dataAgo,
            sub_total_gsu_sep : dataSep,
            sub_total_gsu_oct : dataOct,
            sub_total_gsu_nov : dataNov,
            sub_total_gsu_dic : dataDic,
        }
    }

    return { dataFiltered, filterActive, dataMonths : dataPrevMonth }
}


export const ModifyGsuTonBaselineReducer = (filters_selected, dataBaseline, dataBaselineClone, month, value, filterActive, totalMonths) => {

    let valueTotal = null
    if(month == "06"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_jun : totalMonths.total_gsu_jun
    }else if(month == "07"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_jul : totalMonths.total_gsu_jul
    }else if(month == "08"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_ago : totalMonths.total_gsu_ago
    }else if(month == "09"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_sep : totalMonths.total_gsu_sep
    }else if(month == "10"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_oct : totalMonths.total_gsu_oct
    }else if(month == "11"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_nov : totalMonths.total_gsu_nov
    }else if(month == "12"){
        valueTotal = filterActive ? totalMonths.sub_total_gsu_dic : totalMonths.total_gsu_dic
    }

    let dataFiltered
    dataFiltered = dataBaselineClone.filter(dat => 
        (filters_selected['mdcgba1'] != "" ? dat['masterdataclients']['mdcgba1']?.includes(filters_selected['mdcgba1']) : true) 
        && (filters_selected['mdcgba2'] != "" ? dat['masterdataclients']['mdcgba2']?.includes(filters_selected['mdcgba2']) : true)
        && (filters_selected['mdcfcstgroupcode'] != "" ? dat['masterdataclients']?.['mdcfcstgroupcode']?.includes(filters_selected['mdcfcstgroupcode']) : true)
        && (filters_selected['mdpsector'] != "" ? dat['masterdataproducts']['mdpsector']?.includes(filters_selected['mdpsector']) : true) 
        && (filters_selected['mdpcategoria'] != "" ? dat['masterdataproducts']['mdpcategoria']?.includes(filters_selected['mdpcategoria']) : true) 
        && (filters_selected['mdpsubcategoria'] != "" ? dat['masterdataproducts']['mdpsubcategoria']?.includes(filters_selected['mdpsubcategoria']) : true) 
        && (filters_selected['mdpmarca'] != "" ? dat['masterdataproducts']['mdpmarca']?.includes(filters_selected['mdpmarca']) : true) 
        && (filters_selected['mdpmetrajetalla'] != "" ? dat['masterdataproducts']['mdpmetrajetalla']?.includes(filters_selected['mdpmetrajetalla']) : true) 
        && (filters_selected['mdpformato'] != "" ? dat['masterdataproducts']['mdpformato']?.includes(filters_selected['mdpformato']) : true) 
        && (filters_selected['mdpresumen'] != "" ? dat['masterdataproducts']['mdpresumen']?.includes(filters_selected['mdpresumen']) : true)
    )

    dataFiltered.forEach(item => {
        item.prices.forEach(price => {
            if (price.fecfecha.fecmonth == "06" && month == "06") {
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "07" && month == "07"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "08" && month == "08"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "09" && month == "09"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "10" && month == "10"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "11" && month == "11"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "12" && month == "12"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }
        })
    });

    const deepCopy = JSON.parse(JSON.stringify(dataBaselineClone));

    deepCopy.forEach(item => {
        item.prices.forEach(price => {
            if (price.fecfecha.fecmonth == "06" && month == "06") {
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "07" && month == "07"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "08" && month == "08"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "09" && month == "09"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "10" && month == "10"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "11" && month == "11"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "12" && month == "12"){
                price.privolumen = (value/valueTotal)* price.privolumen
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }
        })
    });

    return {
        newBaseline: dataFiltered, 
    }
}


export const UpdateMixBaselineReducer = (data, id, value) => {

    data.map(dat => {
        if(dat.bslid == id){
            dat['mix'] = value
        }
    })
    
    return data
}

export const GenerateAmountInitialBaselineReducer = (data, fc) => {

    data.forEach(item => {
        item.prices.forEach(price => {
            if (price.fecfecha.fecmonth == "06") {
                price.privolumen = item.bslmix*fc.forecastJun
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "07"){
                price.privolumen = item.bslmix*fc.forecastJul
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "08"){
                price.privolumen = item.bslmix*fc.forecastAgo
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "09"){
                price.privolumen = item.bslmix*fc.forecastSep
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "10"){
                price.privolumen = item.bslmix*fc.forecastOct
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "11"){
                price.privolumen = item.bslmix*fc.forecastNov
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }else if(price.fecfecha.fecmonth == "12"){
                price.privolumen = item.bslmix*fc.forecastDic
                price.pricajas = price.privolumen/parseFloat(item.masterdataproducts.mdpfactorcaja)
                price.prinivlc = price.prinetprice*price.pricajas
            }
        })
    });

    return { 
        fcJun: fc.forecastJun, 
        fcJul: fc.forecastJul, 
        fcAgo: fc.forecastAgo, 
        fcSep: fc.forecastSep, 
        fcOct: fc.forecastOct, 
        fcNov: fc.forecastNov, 
        fcDic: fc.forecastDic, 
        data
    }
}

export const GenerateFiltersBaselineReducer = (dataBaseline) => {

    let data = {}

    // -------------------- SECTOR --------------------
    const uniqueSectors = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpsector))
    );
    
    const filterMdpSectors = uniqueSectors.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)

    // -------------------- CATEGORIA --------------------

    const uniqueCategories = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpcategoria))
    );
    
    const filterMdpCategories = uniqueCategories.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)
    

    // -------------------- MARCA --------------------

    const uniqueBrands = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpmarca))
    );
    
    const filterMdpBrands = uniqueBrands.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)
    
    
    // -------------------- GBA1 --------------------
    
    const uniqueGbaOne = Array.from(
        new Set(dataBaseline.map(item => item.masterdataclients?.mdcgba1))
    );

    const filterMdpGbaOne = uniqueGbaOne.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)

    
    
    // -------------------- GBA2 --------------------
    const uniqueGbaTwo = Array.from(
        new Set(dataBaseline.map(item => item.masterdataclients?.mdcgba2))
    );

    const filterMdpGbaTwo = uniqueGbaTwo.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)
    

    // -------------------- FC GRP COD --------------------
    const uniqueFcGrpCod = Array.from(
        new Set(dataBaseline.map(item => item.masterdataclients?.mdcfcstgroupcode))
    );

    const filterFcGrpCod = uniqueFcGrpCod.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null && sec.label != '##')

        // -------------------- SUBCATEGORY --------------------
    const uniqueSizes = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpmetrajetalla))
    );

    const filterMdpSizes = uniqueSizes.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)

    // -------------------- SUBCATEGORY --------------------
    const uniqueSubcategory = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpsubcategoria))
    );

    const filterMdpSubcategory = uniqueSubcategory.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)

    // -------------------- SUBCATEGORY --------------------
    const uniqueFormat = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpformato))
    );

    const filterMdpFormat = uniqueFormat.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)

    // -------------------- RESUMEN --------------------
    const uniqueSummary = Array.from(
        new Set(dataBaseline.map(item => item.masterdataproducts?.mdpresumen))
    );

    const filterMdpSummaries = uniqueSummary.map(sector => ({
        value: sector,
        label: sector
    })).filter(sec => sec.label != null)
    
    data = {
        ...data,
        sectors         : filterMdpSectors,
        categories      : filterMdpCategories,
        subcategories   : filterMdpSubcategory,
        brands          : filterMdpBrands,
        gbaOne          : filterMdpGbaOne,
        gbaTwo          : filterMdpGbaTwo,
        fcgrpcod        : filterFcGrpCod,
        sizes           : filterMdpSizes,
        formats         : filterMdpFormat,
        summaries       : filterMdpSummaries,
    }

    return data
}

function getSumGsuTon(rex_data_baseline, month) {
    return rex_data_baseline?.reduce((total, producto) => {
      const sumaProducto = producto.prices
        .filter(precio => precio.fecfecha.fecmonth === month)
        .reduce((suma, precio) => suma + parseFloat(precio.privolumen), 0);
      return total + sumaProducto;
    }, 0);
}

function getSumCases(rex_data_baseline, month) {
    return rex_data_baseline?.reduce((total, producto) => {
      const sumaProducto = producto.prices
        .filter(precio => precio.fecfecha.fecmonth === month)
        .reduce((suma, precio) => suma + parseFloat(precio.pricajas), 0);
      return total + sumaProducto;
    }, 0);
}

function getSumNivPx(rex_data_baseline, month) {
    return rex_data_baseline?.reduce((total, producto) => {
      const sumaProducto = producto.prices
        .filter(precio => precio.fecfecha.fecmonth === month)
        .reduce((suma, precio) => suma + parseFloat(precio.prinivprice), 0);
      return total + sumaProducto;
    }, 0);
}

function getSumNivLc(rex_data_baseline, month) {
    return rex_data_baseline?.reduce((total, producto) => {
      const sumaProducto = producto.prices
        .filter(precio => precio.fecfecha.fecmonth === month)
        .reduce((suma, precio) => suma + parseFloat(precio.prinivlc), 0);
      return total + sumaProducto;
    }, 0);
}


export const GenerateTotalMonthsBaselineReducer = (dataBaseline) => {

    let data = {}

    const totalGsuJun = getSumGsuTon(dataBaseline, "06")
    const totalGsuJul = getSumGsuTon(dataBaseline, "07")
    const totalGsuAug = getSumGsuTon(dataBaseline, "08")
    const totalGsuSep = getSumGsuTon(dataBaseline, "09")
    const totalGsuOct = getSumGsuTon(dataBaseline, "10")
    const totalGsuNov = getSumGsuTon(dataBaseline, "11")
    const totalGsuDic = getSumGsuTon(dataBaseline, "12")

    const totalCasesJun = getSumCases(dataBaseline, "06")
    const totalCasesJul = getSumCases(dataBaseline, "07")
    const totalCasesAug = getSumCases(dataBaseline, "08")
    const totalCasesSep = getSumCases(dataBaseline, "09")
    const totalCasesOct = getSumCases(dataBaseline, "10")
    const totalCasesNov = getSumCases(dataBaseline, "11")
    const totalCasesDic = getSumCases(dataBaseline, "12")
    
    const totalNivPxJun = getSumNivPx(dataBaseline, "06")
    const totalNivPxJul = getSumNivPx(dataBaseline, "07")
    const totalNivPxAug = getSumNivPx(dataBaseline, "08")
    const totalNivPxSep = getSumNivPx(dataBaseline, "09")
    const totalNivPxOct = getSumNivPx(dataBaseline, "10")
    const totalNivPxNov = getSumNivPx(dataBaseline, "11")
    const totalNivPxDic = getSumNivPx(dataBaseline, "12")

    const totalNivLcJun = getSumNivLc(dataBaseline, "06")
    const totalNivLcJul = getSumNivLc(dataBaseline, "07")
    const totalNivLcAug = getSumNivLc(dataBaseline, "08")
    const totalNivLcSep = getSumNivLc(dataBaseline, "09")
    const totalNivLcOct = getSumNivLc(dataBaseline, "10")
    const totalNivLcNov = getSumNivLc(dataBaseline, "11")
    const totalNivLcDic = getSumNivLc(dataBaseline, "12")

    data = {
        ...data,
        total_gsu_jun : totalGsuJun,
        total_gsu_jul : totalGsuJul,
        total_gsu_ago : totalGsuAug,
        total_gsu_sep : totalGsuSep,
        total_gsu_oct : totalGsuOct,
        total_gsu_nov : totalGsuNov,
        total_gsu_dic : totalGsuDic,

        total_cases_jun : totalCasesJun,
        total_cases_jul : totalCasesJul,
        total_cases_ago : totalCasesAug,
        total_cases_sep : totalCasesSep,
        total_cases_oct : totalCasesOct,
        total_cases_nov : totalCasesNov,
        total_cases_dic : totalCasesDic,

        total_nivpx_jun : totalNivPxJun,
        total_nivpx_jul : totalNivPxJul,
        total_nivpx_ago : totalNivPxAug,
        total_nivpx_sep : totalNivPxSep,
        total_nivpx_oct : totalNivPxOct,
        total_nivpx_nov : totalNivPxNov,
        total_nivpx_dic : totalNivPxDic,

        total_nivlc_jun : totalNivLcJun,
        total_nivlc_jul : totalNivLcJul,
        total_nivlc_ago : totalNivLcAug,
        total_nivlc_sep : totalNivLcSep,
        total_nivlc_oct : totalNivLcOct,
        total_nivlc_nov : totalNivLcNov,
        total_nivlc_dic : totalNivLcDic,
        
    }

    return data
}

export const GenerateTotalNivPricesBaselineReducer = (dataBaseline) => {
    let dataMonths = {}

    let dataJun = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "06") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);

    let dataJul = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "07") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);


    let dataAgo = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "08") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);

    let dataSep = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "09") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);

    let dataOct = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "10") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);

    let dataNov = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "11") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);

    let dataDic = dataBaseline.reduce((acc, item) => {
        const sum = item.prices.reduce((innerAcc, price) => {
            if (price.fecfecha.fecmonth === "12") {
                return innerAcc + parseFloat(price.privolumen);
            }
            return innerAcc;
        }, 0);
        return acc + sum;
    }, 0);

    dataMonths = {
        ...dataMonths,
        total_gsu_jun : dataJun,
        total_gsu_jul : dataJul,
        total_gsu_ago : dataAgo,
        total_gsu_sep : dataSep,
        total_gsu_oct : dataOct,
        total_gsu_nov : dataNov,
        total_gsu_dic : dataDic,
    }

    return dataMonths
}

export const ChangeFiltersDataBaseline = (data, value, filter, type) => {

    if(value){
        data = {
            ...data,
            [type] : {
                ...data[type],
                [filter] : value
            }
        }
    }else{
        delete data[type][filter]
    }

    if (Object.keys(data[type]).length === 0) {
        delete data[type];
    }

    return {
        data
    }
}
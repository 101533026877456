import { configureStore } from "@reduxjs/toolkit"
import Baseline from "../Reducers/Baseline"
import FileUpload from "../Reducers/Fileupload"
import User from "../Reducers/User"

export const store = configureStore({
    reducer: {
        baseline : Baseline,
        fileUpload: FileUpload,
        user: User,
    }
})
import React, { useEffect, useState } from 'react'
import { Button, Col, Pagination, Row, Table } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getBaselineServices, getDataVersionsFiltersServices, downloadBaselineServices } from '../../Redux/Services/Baseline'
import {
	SwapOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons'
import ModalTransitionProduct from '../../Components/Baseline/ModalTransitionProduct'
import socket from '../../socket'
import { 
	changeModalNewVersion, 
	storePageDataBaseline
} from '../../Redux/Reducers/Baseline'
import ModalNewProduct from '../../Components/Baseline/ModalNewProduct'
import Filters from '../../Components/Baseline/Filters'
import ModalNewVersion from '../../Components/Baseline/ModalNewVersion'
import ModalForescast from '../../Components/Baseline/ModalForescast'
import ModalUpdateGsuTon from '../../Components/Baseline/ModalUpdateGsuTon'
import './../../Styles/Routes/Baseline.css'

const Baseline = () => {

	const [ openModal, setOpenModal ] = useState(false)
	const [ openModalNewProduct, setOpenModalNewProduct ] = useState(false)
	const [ openModalForecast, setOpenModalForecast ] = useState(false)
	const [ dataProductTransition, setDataProductTransition] = useState({})
	const [ dataNewProduct, setDataNewProduct] = useState({})

	const [ openModalGsuTon, setOpenModalGsuTon ] = useState(false)
	const [ dataPeriod, setDataPeriod ] = useState({})

	const { 
		rex_data_baseline,
		rex_data_total_months,
		rex_data_version_selected,
		rex_filter_active,
		rex_file_paths,
		rex_total_data_baseline,
		rex_page_data_baseline,
		rex_filter_data_baseline,
		rex_data_metrics,
		rex_total_metrics_baseline,
		rex_data_new_versions,
		rex_cambios_pre_version,
		rex_data_columns_baseline
	} = useSelector(state => state.baseline)

	const dispatch = useDispatch()

	const { isLoading: isLoadingVersions, data: response_vers } = useQuery({
		queryKey: ['versions'],
		queryFn : () => getDataVersionsFiltersServices(dispatch),
		retry: 1,
		refetchOnWindowFocus : false,
	})

	const { isLoading: isLoadingBaseline } = useQuery({
		queryKey: ['baseline'],
		queryFn : () => getBaselineServices(
							dispatch, 
							rex_data_version_selected, 
							rex_page_data_baseline,
							rex_filter_data_baseline
						),
		retry: 1,
		refetchOnWindowFocus : false,
		enabled: !!response_vers
	})

	function getSumGsuTon(mix, totalAmout) {
		
		const totalMix = rex_data_baseline?.reduce((acumulador, actual) => 
			acumulador + (actual.bslmix !== null ? parseFloat(actual.bslmix) : 0), 
		0);

		const percentage = (mix / totalMix);
		return parseFloat(totalAmout*percentage).toFixed(2)
	}

	function formattedNumber(value){

		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(value);
	}

	function formattedNameMonth(value){
		return value.charAt(0).toUpperCase() + value.slice(1, 3) + ".";
	}

	const columns = [
		{
			title: 'N°',
			dataIndex: 'key',
			key: 'key',
			fixed: 'left',
			width : 40,
			ellipsis: true,
		},
		{
			title: 'GBA1',
			dataIndex: 'mdcgba1',
			key: 'mdcgba1',
			fixed: 'left',
			width : 110,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataclients?.mdcgba1 ? record.masterdataclients?.mdcgba1 : '-'}
				>
					{record.masterdataclients?.mdcgba1 ? record.masterdataclients?.mdcgba1 : '-'}
				</div>
		},
		{
			title: 'GBA2',
			dataIndex: 'mdcgba2',
			key: 'mdcgba2',
			fixed: 'left',
			width : 70,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataclients?.mdcgba2 ? record.masterdataclients?.mdcgba2 : '-'}
				>
					{record.masterdataclients?.mdcgba2 ? record.masterdataclients?.mdcgba2 : '-'}
				</div>
		},
		{
			title: 'FC GRP COD',
			dataIndex: 'mdcfcstgroupcode',
			key: 'mdcfcstgroupcode',
			fixed: 'left',
			width : 90,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataclients?.mdcfcstgroupcode ? record.masterdataclients?.mdcfcstgroupcode : '-'}
				>
					{record.masterdataclients?.mdcfcstgroupcode ? record.masterdataclients?.mdcfcstgroupcode : '-'}
				</div>
		},
		{
			title: 'FC GRP DESC',
			dataIndex: 'mdcfcstgroup',
			key: 'mdcfcstgroup',
			fixed: 'left',
			ellipsis: true,
			width : 140,
			render: (_, record) => 
				<div 
					title={record.masterdataclients?.mdcfcstgroup ? record.masterdataclients?.mdcfcstgroup : '-'}
				>
					{record.masterdataclients?.mdcfcstgroup ? record.masterdataclients?.mdcfcstgroup : '-'}
				</div>
		},
		{
			title: 'SKU',
			dataIndex: 'mdpmaterialnumber',
			key: 'mdpmaterialnumber',
			fixed: 'left',
			width : 80,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpmaterialnumber ? record.masterdataproducts?.mdpmaterialnumber : '-'}
				>
					{record.masterdataproducts?.mdpmaterialnumber ? record.masterdataproducts?.mdpmaterialnumber : '-'}
				</div>
		},
		{
			title: 'P&L',
			dataIndex: 'mdpdescripcion',
			key: 'mdpdescripcion',
			width : 240,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpdescripcion ? record.masterdataproducts?.mdpdescripcion : '-'}
				>
					{record.masterdataproducts?.mdpdescripcion ? record.masterdataproducts?.mdpdescripcion : '-'}
				</div>
		},
		{
			title: 'SECTOR',
			dataIndex: 'mdpsector',
			key: 'mdpsector',
			width : 70,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpsector ? record.masterdataproducts?.mdpsector : '-'}
				>
					{record.masterdataproducts?.mdpsector ? record.masterdataproducts?.mdpsector : '-'}
				</div>
		},
		{
			title: 'CATEGORIA',
			dataIndex: 'mdpcategoria',
			key: 'mdpcategoria',
			width : 90,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpcategoria ? record.masterdataproducts?.mdpcategoria : '-'}
				>
					{record.masterdataproducts?.mdpcategoria ? record.masterdataproducts?.mdpcategoria : '-'}
				</div>
		},
		{
			title: 'SUB-CATEGORIA',
			dataIndex: 'mdpsubcategoria',
			key: 'mdpsubcategoria',
			width : 120,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpsubcategoria ? record.masterdataproducts?.mdpsubcategoria : '-'}
				>
					{record.masterdataproducts?.mdpsubcategoria ? record.masterdataproducts?.mdpsubcategoria : '-'}
				</div>
		},
		{
			title: 'MARCA',
			dataIndex: 'mdpmarca',
			key: 'mdpmarca',
			width : 90,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpmarca ? record.masterdataproducts?.mdpmarca : '-'}
				>
					{record.masterdataproducts?.mdpmarca ? record.masterdataproducts?.mdpmarca : '-'}
				</div>
		},
		{
			title: 'METRAJE/TALLA',
			dataIndex: 'mdpmetrajetalla',
			key: 'mdpmetrajetalla',
			width : 120,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpmetrajetalla ? record.masterdataproducts?.mdpmetrajetalla : '-'}
				>
					{record.masterdataproducts?.mdpmetrajetalla ? record.masterdataproducts?.mdpmetrajetalla : '-'}
				</div>
		},
		{
			title: 'FORMATO',
			dataIndex: 'mdpformato',
			key: 'mdpformato',
			width : 110,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpformato ? record.masterdataproducts?.mdpformato : '-'}
				>
					{record.masterdataproducts?.mdpformato ? record.masterdataproducts?.mdpformato : '-'}
				</div>
		},
		{
			title: 'RESUMEN',
			dataIndex: 'mdpresumen',
			key: 'mdpresumen',
			width : 90,
			ellipsis: true,
			render: (_, record) => 
				<div 
					title={record.masterdataproducts?.mdpresumen ? record.masterdataproducts?.mdpresumen : '-'}
				>
					{record.masterdataproducts?.mdpresumen ? record.masterdataproducts?.mdpresumen : '-'}
				</div>
		},
		// GSUTON
		{
			title : <div >GSU/TON</div>,
			children : rex_data_columns_baseline.map(col => ({
				title: <div>
					<div
						onDoubleClick={() => {
							setOpenModalGsuTon(true)
							// setDataPeriod({valuePrev:rex_total_metrics_baseline['sumTotalAgo']?.['privolumen'], month:'08', year:'2024', name: 'sumTotalAgo'})
							setDataPeriod({valuePrev: col.data.sumvolumen, month:col.fecmonth, year: col.fecyear, name: 'sumvolumen'})
						}}
					>
						{formattedNumber(col.data.sumvolumen)}
					</div>
					<div>{formattedNameMonth(col.fecmonthtext)}</div>
				</div>,
				dataIndex: 'privolumen',
				key: 'privolumen',
				align : 'center',
				width: 110,
				ellipsis: true,
				render : (_, record) => {
					const find_prices = record.prices.find(pri => pri.fecfecha.fecmonth == col.fecmonth && pri.fecfecha.fecyear == col.fecyear)

					return (
						<div>{find_prices ? formattedNumber(find_prices.privolumen): '0.00'}</div>
					)
				}
			}))
		},
		// CAJAS
		{
			title : 'CAJAS',
			children : rex_data_columns_baseline.map(col => ({
				title: <div>
					<div
						// onDoubleClick={() => console.log("Click")}
					>
						{formattedNumber(col.data.sumcajas)}
					</div>
					<div>{formattedNameMonth(col.fecmonthtext)}</div>
				</div>,
				dataIndex: 'pricajas',
				key: 'pricajas',
				align : 'center',
				width: 110,
				ellipsis: true,
				render : (_, record) => {
					const find_prices = record.prices.find(pri => pri.fecfecha.fecmonth == col.fecmonth && pri.fecfecha.fecyear == col.fecyear)

					return (
						<div>{find_prices ? formattedNumber(find_prices.pricajas) : '0.00'}</div>
					)
				}
			}))
		},
		// NIV pX
		{
			title: 'NIV PRICES',
			children : rex_data_columns_baseline.map(col => ({
				title: <div>
					<div
						// onDoubleClick={() => console.log("Click")}
					>
						{formattedNumber(col.data.sumnivprice)}
					</div>
					<div>{formattedNameMonth(col.fecmonthtext)}</div>
				</div>,
				dataIndex: 'prinivprice',
				key: 'prinivprice',
				align : 'center',
				width: 110,
				ellipsis: true,
				render : (_, record) => {
					const find_prices = record.prices.find(pri => pri.fecfecha.fecmonth == col.fecmonth && pri.fecfecha.fecyear == col.fecyear)

					return (
						<div>{find_prices ? formattedNumber(find_prices.prinivprice) : '0.00'}</div>
					)
				}
			}))
		},
		{
			title : 'NIV LC',
			children : rex_data_columns_baseline.map(col => ({
				title: <div>
					<div
						// onDoubleClick={() => console.log("Click")}
					>
						{formattedNumber(col.data.sumnivlc)}
					</div>
					<div>{formattedNameMonth(col.fecmonthtext)}</div>
				</div>,
				dataIndex: 'prinivlc',
				key: 'prinivlc',
				align : 'center',
				width: 110,
				ellipsis: true,
				render : (_, record) => {
					const find_prices = record.prices.find(pri => pri.fecfecha.fecmonth == col.fecmonth && pri.fecfecha.fecyear == col.fecyear)

					return (
						<div>{find_prices ? formattedNumber(find_prices.prinivlc) : '0.00'}</div>
					)
				}
			}))
		},
		// {
		// 	title: 'Acción',
		// 	dataIndex: 'action',
		// 	key: 'action',
		// 	align: 'center',
		// 	fixed : 'right',
		// 	width : 50,
		// 	render: (_, record) => (
		// 		<div
		// 			style={{
		// 				display:'flex',
		// 				justifyContent:'center',
		// 				gap:'5px'
		// 			}}
		// 		>
		// 			<div title='Transicionar producto'>
		// 				<SwapOutlined
		// 					onClick={() => {
		// 						setDataProductTransition(record.masterdataproducts)
		// 						setOpenModal(true)
		// 					}}
		// 					style={{
		// 						cursor:'pointer'
		// 					}}
		// 				/>
		// 			</div>
		// 			{/* <div title='Nuevo producto'>
		// 				<PlusCircleOutlined
		// 					onClick={() => {
		// 						setDataNewProduct({...dataNewProduct, record})
		// 						setOpenModalNewProduct(true)
		// 					}}
		// 					style={{
		// 						cursor:'pointer'
		// 					}}
		// 				/>
		// 			</div> */}
		// 		</div>
		// 	),
		// },
	]

	const getBaselinePageServices = useMutation({
		mutationFn: (page) => getBaselineServices(dispatch, rex_data_version_selected, page, rex_filter_data_baseline)
	})

	const downloadBaseline = useMutation({
		mutationFn: () => downloadBaselineServices(rex_data_version_selected)
	})

	const changePageTable = (page) => {
		dispatch(storePageDataBaseline(page))
		getBaselinePageServices.mutate(page)
	}

	// useEffect(() => {
	// 	console.log("Inicio")
	// 	socket.emit('listen-status-dashboard','','');
	// },[])

	return (
		<Row className='ContainerBaseline'>
			<Col span={24} style={{display:'flex', alignItems:'center', gap:'10px'}}>
				<Filters />
			</Col>
			<Col span={24}>
				<div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0 0 0'}}>
					<Button
						size='small'
						onClick={() => downloadBaseline.mutate()}
						loading={downloadBaseline.isPending}
					>Descargar Baseline</Button>
					<div>
						{/* <Button
							size='small'
							style={{marginRight: '10px'}}
							onClick={() => setOpenModalForecast(true)}
						>Asignar Forecast</Button> */}
						<Button 
							type='primary'
							size='small'
							onClick={() => dispatch(changeModalNewVersion(true))}
						>
							Guardar cambios en nuevo versionamiento	
						</Button>
					</div>
				</div>
				<Row
					style={{
						display:'flex',
						justifyContent:'end',
						margin:'10px 0'
					}}
				>
					<Col>
						<Pagination
							showSizeChanger={false}
							current={rex_page_data_baseline}
							total={rex_total_data_baseline}
							onChange={changePageTable}
						/>
					</Col>
				</Row>
				<Table
					className='Baseline__Table'
					columns={columns}
					dataSource={rex_data_baseline}
					loading={isLoadingBaseline}
					pagination={false}
					scroll={{
						x:'1000px'
					}}
				/>
				<ModalNewProduct
					openModal={openModalNewProduct}
					setOpenModal={setOpenModalNewProduct}
					dataProductTransition={dataNewProduct}
					setDataProductTransition={setDataNewProduct}
				/>
				<ModalTransitionProduct
					openModal={openModal}
					setOpenModal={setOpenModal}
					dataProductTransition={dataProductTransition}
					setDataProductTransition={setDataProductTransition}
					rex_data_baseline={rex_data_baseline}
				/>
				<ModalNewVersion
					rex_cambios_pre_version={rex_cambios_pre_version}
					rex_data_baseline={rex_data_baseline}
					rex_file_paths={rex_file_paths}
					rex_data_version_selected={rex_data_version_selected}
					rex_filter_data_baseline={rex_filter_data_baseline}
					rex_data_new_versions={rex_data_new_versions}
				/>
				<ModalForescast
					openModal={openModalForecast}
					setOpenModal={setOpenModalForecast}
				/>
				<ModalUpdateGsuTon
				    openModal={openModalGsuTon}
					setOpenModal={setOpenModalGsuTon}
					dataPeriodo={dataPeriod}
					rex_filter_data_baseline={rex_filter_data_baseline}
					setDataPeriodo={setDataPeriod}
				/>
			</Col>
		</Row>
	)
}

export default Baseline
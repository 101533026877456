import { Button, Col, Form, Input, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSumTotalForecast, updateTotalGSU } from '../../Redux/Reducers/Baseline'
import {
    LoadingOutlined
} from '@ant-design/icons'

const ModalUpdateGsuTon = (
{
    openModal=false,
    setOpenModal=()=>{},
    dataPeriodo,
    setDataPeriodo,
    rex_filter_data_baseline
}) => {

	const { 
        rex_info_status_dashboard
	} = useSelector(state => state.baseline)

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const onFinishForm = async (values) => {

        // dispatch(updateSumTotalForecast({value: values.gsuNew, month: dataPeriodo.month}))
        dispatch(updateTotalGSU({ value: Number(values.gsuNew), month: dataPeriodo.month, year: dataPeriodo.year, name: dataPeriodo.name }))

        let previousChanges = [
            {
                "month"     : dataPeriodo.month,
                "value"     : values.gsuNew,
                "filters"   : rex_filter_data_baseline
            }
        ]
        
        setOpenModal(false)
        form.resetFields()
    }

    function formattedNumber(value){

		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(value);
	}

    useEffect(() => {

    }, [dataPeriodo])
    return (
        <Modal
            title={<div className='ModalNewProduct__Title'>Actualizar GSU/TON</div>} 
            open={openModal}
            onCancel={() => {
                setDataPeriodo({})
                setOpenModal(false)
            }}
            footer={null}
            width={550}
        >
            {
                rex_info_status_dashboard.code == "OnExecution"
                ? <div><LoadingOutlined /> En estos momentos el <b>Deck</b> y <b>AoC</b> se encuentran actualizandose con los últimos cambios realizados.</div>
                :   <Form
                        form={form}
                        name="modalUpdateGsu"
                        onFinish={onFinishForm}
                        layout='vertical'
                    >
                        <Row gutter={[12,12]}>
                            <Col span={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                Actual: {formattedNumber(dataPeriodo.valuePrev)}
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Nuevo:'
                                    name='gsuNew'
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{display:'flex', justifyContent:'end', gap:'10px'}}>
                                <Button danger>Cancelar</Button>
                                <Button 
                                    type='primary'
                                    htmlType="submit"
                                >Guardar</Button>
                            </Col>
                        </Row>
                    </Form>
            }
            
        </Modal>
    )
}

export default ModalUpdateGsuTon
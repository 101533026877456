import config from "../../config"
import socket from "../../socket"
import { storeDataBaseline, storeDataVersion, storeDataVersionSelected, storeDataSku, storeDataSector, storeDataCategoria, storeDataMarca, storeDataBaselineClone, updateTotalNivPricesBaseline, changeModalNewVersion, changeFilePaths, storeTotalDataBaseline, storeDataMetricsBaseline, storeDataMdp, storeDataMdc, storeTotalMetricsBaseline, changeGba1Mdc, changeGba2Mdc, changeFcGrpCodMdc, changeSectorMdp, changeCategoriaMdp, changeSubcategoriaMdp, changeMarcaMdp, changeMetrajeTallaMdp, changeFormatoMdp, changeResumenMdp, storeStatusDashboard, updateStatusCreateVersionBaseline, storeDataColumnsBaseline } from "../Reducers/Baseline"
import { notification } from 'antd'


const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const getBaselineServices = async (dispatch, version_selected, page=1, filter_data) => {
    let data_baseline = []
    await fetch(config.api_url+`baseline/get-data?page=${page}`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_id_version: version_selected.value ? version_selected.value : null,
            req_filters : filter_data
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            await dispatch(storeTotalMetricsBaseline(data.metricsg))

            await dispatch(storeDataMetricsBaseline(data.metricsg))
            await dispatch(storeDataVersionSelected(data.deckInfo))
            await dispatch(storeDataColumnsBaseline(data.sumMetrics))

            await dispatch(storeTotalDataBaseline(data.data_total))
            let data_new = data.data
            data_new.map((dn, index) => {
                data_new[index]['key'] = index + 1
            })
            
            await dispatch(storeDataBaseline({prevData:data_new, metrics : data.metricsg}))
            await dispatch(storeDataBaselineClone(data_new))
            // dispatch(updateTotalNivPricesBaseline(data_new))
            
            

            // dispatch(getUpdateStatusServices())
        }else{
            notificacionServidor('error', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}

export const getDataVersionsFiltersServices = async (dispatch) => {
    await getDataVersionsServices(dispatch)
    await getDataFiltersServices(dispatch)

    return true
}

export const refreshDashboardServices = async (dispatch) => {

    const datasetId = "e695050e-3b9c-4397-acf4-a6ad581218b8"
    let errorRefresh = false
    
    await fetch(`https://p39kwkkvv4.execute-api.us-east-2.amazonaws.com/production/execute?datasetid=${datasetId}`, {
        mode:'cors',
        method: 'POST',
        headers: {
            'x-api-key' : 'M8SpLdHRwqaQI7CqDPgaYaYEJnqK899KaVg9KlvR',
        },
    })
    .then(res => res.json())
    .then(data => {
        console.log("data execute")
        console.log(data)
        if(data.message == "Limit Exceeded"){
            notificacionServidor('error', "Ha ocurrido un error al actualizar el Deck y AoC")
        }

        dispatch(getUpdateStatusServices(dispatch, true))
    })
    .catch(error => {
        console.log(error)
        errorRefresh = true
    })

    return true
}

export const getUpdateStatusServices = async (dispatch, update = false) => {

    // console.log("Ejecuta get status")

    // console.log("El status del action es: " + update)
    // let status = {}
    // if(update){
    //     status = {
    //         ...status,
    //         code : "OnExecution",
    //         status : 1,
    //         description : "El dashboard se Encuentra actualizandose",
    //     }
    // }else{
    //     status = {
    //         ...status,
    //         code : "RefreshRequestSucceed",
    //         status : 2,
    //         description : "El dashboard se ha actualizado con éxito",    
    //     }
    // }
    // const status = {
    //     code : "OnExecution",
    //     status : 1,
    //     description : "El dashboard se Encuentra actualizandose",
    // }
    // const status = {
    //     code : "RefreshRequestSucceed",
    //     status : 2,
    //     description : "El dashboard se ha actualizado con éxito",
    // }

    const dataSetId = 'e695050e-3b9c-4397-acf4-a6ad581218b8'
    
    await fetch(`https://p39kwkkvv4.execute-api.us-east-2.amazonaws.com/production/check?datasetid=${dataSetId}`, {
        mode:'cors',
        method: 'GET',
        headers: {
            'x-api-key' : 'M8SpLdHRwqaQI7CqDPgaYaYEJnqK899KaVg9KlvR',
        },
    })
    .then(res => res.json())
    .then(data => {
        let status = {}
        console.log("data.status")
        console.log(data.status)
        if(data.status == "OnExecution"){
            status = {
                ...status,
                code : "OnExecution",
                status : 1,
                description : "El dashboard se encuentra actualizandose",
            }
        }else if(data.status == "RefreshRequestSucceed"){
            status = {
                ...status,
                code : "RefreshRequestSucceed",
                status : 2,
                description : "El dashboard se ha actualizado con éxito",
            }
        }else if(data.status == "Idle"){
            status = {
                ...status,
                code : "Idle",
                status : 0,
                description : "El dashboard se encuentra actualizado",
            }
        }else{
            status = {
                ...status,
                code : "Fail",
                status : 9,
                description : "Ha ocurrido un error al actualizar el dashboard",
            }
        }

        socket.emit('listen-status-dashboard', status);

    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const getStatusDashboardServices = async (dispatch) => {
    
    await fetch(config.api_url+'deck/get-status-dashboard', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
    })
    .then(res => res.json())
    .then(data => {
        console.log("getStatusDashboardServices")
        console.log(data)
        dispatch(storeStatusDashboard({
            code : data.data.esdcode,
            status : data.data.esdestado,
            description : data.data.esddescripcion,
        }))
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const getDataVersionsServices = async (dispatch) => {
    await fetch(config.api_url+'baseline/get-data-versions', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({})
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_version = data.data_version.map(ver => ({value: ver.mdeckid, label: ver.mdeckname}))
            const data_version_last = data_version[data_version.length-1]
            if(data_version_last){
                dispatch(storeDataVersionSelected({ value: data_version_last.value, label: data_version_last.label }))
            }
            dispatch(storeDataVersion(data_version))
        }else{
            notificacionServidor('error', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const getDataFiltersServices = async (dispatch) => {
    await fetch(config.api_url+'baseline/get-data-filters', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({})
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const mdc_data = data.get_mdc
            const mdp_data = data.get_mdp
            dispatch(storeDataMdp(data.get_mdp))
            dispatch(storeDataMdc(data.get_mdc))

            const gba1 = mdc_data.filter(mdc => mdc.mdcgba1).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba1 === mdc.mdcgba1))).map(mdc => ({label: mdc.mdcgba1, value: mdc.mdcgba1}))
            const gba2 = mdc_data.filter(mdc => mdc.mdcgba2).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba2 === mdc.mdcgba2))).map(mdc => ({label: mdc.mdcgba2, value: mdc.mdcgba2}))
            const fcgrpcod = mdc_data.filter(mdc => mdc.mdcfcstgroupcode).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcfcstgroupcode === mdc.mdcfcstgroupcode))).map(mdc => ({label: mdc.mdcfcstgroupcode, value: mdc.mdcfcstgroupcode}))
            dispatch(changeGba1Mdc(gba1))
            dispatch(changeGba2Mdc(gba2))
            dispatch(changeFcGrpCodMdc(fcgrpcod))
            
            const sector = mdp_data.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
            const categoria = mdp_data.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
            const subcategoria = mdp_data.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
            const marca = mdp_data.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
            const metrajeTalla = mdp_data.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
            const formato = mdp_data.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
            const resumen = mdp_data.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
            dispatch(changeSectorMdp(sector))
            dispatch(changeCategoriaMdp(categoria))
            dispatch(changeSubcategoriaMdp(subcategoria))
            dispatch(changeMarcaMdp(marca))
            dispatch(changeMetrajeTallaMdp(metrajeTalla))
            dispatch(changeFormatoMdp(formato))
            dispatch(changeResumenMdp(resumen))
        }else{
            notificacionServidor('error', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })

    return true
}

export const createTransitionProductServices = async (dispatch, data_value) => {
    await fetch(config.api_url+'baseline/baseline-products-transition', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_new_material_number: data_value.codeTransition,
            req_new_description: data_value.descriptionTransition,
            req_material_number: data_value.sku,
            req_date: data_value.dateTransition,
        })
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('error', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}

export const createNewProductServices = async (dispatch, data_value) => {
    await fetch(config.api_url+'baseline/baseline-products-new', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            // req_new_material_number: data_value.codeTransition,
            // req_new_description: data_value.descriptionTransition,
            // req_material_number: data_value.sku,
            // req_date: data_value.dateTransition,
        })
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('error', data.message)
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}

export const createNewVersionServices = async (dispatch, cambios, data, version, values) => {

    dispatch(updateStatusCreateVersionBaseline(true))

    let refreshDashboard = false

    await fetch(config.api_url+'baseline/create-new-version-baseline', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_data    : data,
            req_name_ver: values.nombreversionamiento,
            req_version : version.value,
            req_cambios : cambios
        })
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            refreshDashboard = true
            notificacionServidor('success', "El versionamiento se ha creado con éxito")
        }else{
            console.log("no, front test")
            notificacionServidor('error', "Ha ocurrido un error al crear el versionamiento")
        }
        const take = 2000
        const skip = 0
        let file_paths = []
        let indice = 1
        await GenerateExcelVersionServices(version.value, take, skip, file_paths, indice)
    })
    .catch(error => {
        console.log(error)
    })
    dispatch(changeModalNewVersion(false))
    dispatch(updateStatusCreateVersionBaseline(false))
    // if(refreshDashboard){
    //     console.log("Ejecutará refresh")
    //     dispatch(refreshDashboardServices(dispatch))
    // }else{
    //     console.log("No ejecutará refresh")
    // }
}

export const getMetricsBaselineFetchServices = async (data, nombre, indice, last_indice, file_paths) => {
    
    await fetch(config.api_url+'baseline/get-data-metrics', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_data: data,
        })
    })
    .then(res => res.json())
    .then(data => {
        // file_paths.push(data.filePath)
    })
    .catch(error => {
        console.log(error)
    })
}

export const DownloadVersionServices = async (value_version) => {
    await fetch(config.api_url+'baseline/download-version', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_id_deck: value_version,
        })
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            if(data.data){
                const carcargasarchivos = data.data
                const link = document.createElement('a')
                link.href = carcargasarchivos.cararchivo
                link.download = carcargasarchivos.carnombre
                link.click()
            }
        }else{
            if(data.statusCode == 400){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
        throw new Error('Los sentimos hubo un error en el servidor.')
    })
}

export const GenerateExcelVersionServices = async (id_version, take, skip, file_paths, indice) => {

    await fetch(config.api_url+'baseline/generate-excel-version', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_id_version: id_version,
            req_take_version: take,
            req_skip_version: skip,
            req_filePaths: file_paths,
            req_indice: indice,
        })
    })
    .then(res => res.json())
    .then(async data => {
        file_paths.push(data.filePath)
        if(data.response_missing_data){
            indice = data.indice
            await GenerateExcelVersionServices(id_version, take, data.skip, file_paths, indice)
        }
    })
    .catch(error => {
        console.log(error)
    })
}

export const downloadBaselineServices = async (version) => {
    await fetch(config.api_url+'baseline/download-excel-baseline', {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken': 'token',
        },
        body: JSON.stringify({
            req_id_version: version.value,
            req_name_version: version.label,
        })
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            if(data.data){
                const archivo_baseline = data.data
                const link = document.createElement('a')
                link.href = archivo_baseline.blaarchivo
                link.download = archivo_baseline.blanombre
                link.click()
            }
        }else{
            if(data.statusCode == 400){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
            throw new Error(`${data.message}`)
        }
    })
    .catch(error => {
        console.log(error)
    })
}
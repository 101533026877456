import './App.css'
import RoutesApp from './Routes/RoutesApp'
import Login from './Routes/Login/Login'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function App() {
	const { rex_data_user } = useSelector(state => state.user)

	const validateToken = () => {
        return !localStorage.getItem('usutoken') ? false : true
    }

	return (
		<>
			{
				validateToken()
					? rex_data_user
						? <RoutesApp/>
						: <RoutesApp/>
				: 	rex_data_user
					? 	<Routes>
							<Route path="*" element={<Navigate replace to="/login" />} />
							<Route exact path='/login' element={validateToken() ? <Navigate replace to="/data/baseline" /> : <Login/>}/>
						</Routes>
					:	<Routes>
							<Route path="*" element={<Navigate replace to="/login" />} />
							<Route exact path='/login' element={validateToken() ? <Navigate replace to="/data/baseline" /> : <Login/>}/>
						</Routes>
			}
		</>
	)
}

export default App

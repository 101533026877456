import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DownloadVersionServices, getBaselineServices, getFiltersServices } from '../../Redux/Services/Baseline'
import { Col, Row, Select, Button } from 'antd'
import { changeFilterBaseline, storeDataVersionSelected, storeFiltersDataBaseline, storePageDataBaseline, changeGba1Mdc, changeGba2Mdc, changeFcGrpCodMdc, changeSectorMdp, changeCategoriaMdp, changeSubcategoriaMdp, changeMarcaMdp, changeMetrajeTallaMdp, changeFormatoMdp, changeResumenMdp, changeDataFilterBaseline } from '../../Redux/Reducers/Baseline'
import '../../Styles/Components/Baseline/Filters.css'
import { useEffect } from 'react'

const Filters = () => {
    const { 
		rex_data_version,
        rex_data_baseline,
        rex_data_version_selected,
        rex_page_data_baseline,
        rex_filter_data_baseline,
        rex_data_mdp,
        rex_data_mdc,
        rex_gba1_mdc,
        rex_gba2_mdc,
        rex_fc_grp_cod_mdc,
        rex_sector_mdp,
        rex_categoria_mdp,
        rex_subcategoria_mdp,
        rex_marca_mdp,
        rex_metraje_talla_mdp,
        rex_formato_mdp,
        rex_resumen_mdp,
        rex_data_filter_selected_baseline,
	} = useSelector(state => state.baseline)

    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const downloadVersion = useMutation({
        mutationFn: () => DownloadVersionServices(rex_data_version_selected.value),
    })

    const getBaselinePageServices = useMutation({
		mutationFn: () => getBaselineServices(
                            dispatch,
                            rex_data_version_selected, 
                            rex_page_data_baseline,
                            rex_filter_data_baseline
                        ),
        onSuccess: () => {}
	})

    const changeFiltersBaseline = (value, type) => {
        let data_filter_select_baseline = rex_data_filter_selected_baseline
        
        if(type == "mdcgba1"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdcgba1: value,
            }
        }else if(type == "mdcgba2"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdcgba2: value,
            }
        }else if(type == "mdcfcstgroupcode"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdcfcstgroupcode: value,
            }
        }else if(type == "mdpsector"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpsector: value,
            }
        }else if(type == "mdpcategoria"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpcategoria: value,
            }
        }else if(type == "mdpsubcategoria"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpsubcategoria: value,
            }
        }else if(type == "mdpmarca"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpmarca: value,
            }
        }else if(type == "mdpmetrajetalla"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpmetrajetalla: value,
            }
        }else if(type == "mdpformato"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpformato: value,
            }
        }else if(type == "mdpresumen"){
            data_filter_select_baseline = {
                ...data_filter_select_baseline,
                mdpresumen: value,
            }
        }

        dispatch(changeDataFilterBaseline(data_filter_select_baseline))
    }

    const handleFilterDataBaseline = async (value, filter, type) => {
        await dispatch(storeFiltersDataBaseline({
            value,
            filter,
            type
        }))
        await dispatch(storePageDataBaseline(1))
        getBaselinePageServices.mutate()
    }

    useEffect(() => {
    }, [rex_data_baseline])

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={24} style={{display:'flex', alignItems:'center', gap:'10px', justifyContent: 'end', marginBottom: '10px'}}>
                    <div>Version:</div>
                    <Select
                        size='small'
                        style={{
                            width: 150,
                        }}
                        value={rex_data_version_selected?.label ? rex_data_version_selected?.label : ''}
                        onChange={async (e, option) => {
                            const values = { value: option.value, label: option.label }
                            await dispatch(storeDataVersionSelected(values))
                            await dispatch(storePageDataBaseline(1))
                            queryClient.invalidateQueries('baseline')
                        }}
                        options={rex_data_version}
                    />
                    <Button 
                        className='Button-Green'
                        onClick={() => downloadVersion.mutate(rex_data_version_selected)}
                        size='small'
                        loading={downloadVersion.isPending}
                    >
                        Descargar Versionamiento
                    </Button>
                </Col>
                <Col span={24} style={{display:'flex', alignItems:'center', gap:'10px'}}>
                    <Select
                        size='small'
                        showSearch
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdcgba2 && !rex_data_filter_selected_baseline.mdcfcstgroupcode){
                                let data_mdc = rex_data_mdc
                                const gba1_mdc = data_mdc.filter(mdc => mdc.mdcgba1).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba1 === mdc.mdcgba1))).map(mdc => ({label: mdc.mdcgba1, value: mdc.mdcgba1}))
                                console.log("gba1_mdc", gba1_mdc);
                                dispatch(changeGba1Mdc(gba1_mdc))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdcgba1', 'masterdataclients')
                            changeFiltersBaseline(value, "mdcgba1")
                            let data_mdc = rex_data_mdc
                            if(rex_data_filter_selected_baseline.mdcgba2){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcgba2 === rex_data_filter_selected_baseline.mdcgba2)
                            }
                            if(rex_data_filter_selected_baseline.mdcfcstgroupcode){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcfcstgroupcode === rex_data_filter_selected_baseline.mdcfcstgroupcode)
                            }
                            if(value != undefined){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcgba1 == value)
                            }
                            const gba2_mdc = data_mdc.filter(mdc => mdc.mdcgba2).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba2 === mdc.mdcgba2))).map(mdc => ({label: mdc.mdcgba2, value: mdc.mdcgba2}))
                            const fcgrpcod_mdc = data_mdc.filter(mdc => mdc.mdcfcstgroupcode).filter((gc, index, self) => index === self.findIndex((t) => (t.mdcfcstgroupcode === gc.mdcfcstgroupcode))).map(gc => ({label: gc.mdcfcstgroupcode, value: gc.mdcfcstgroupcode}))

                            dispatch(changeGba2Mdc(gba2_mdc))
                            dispatch(changeFcGrpCodMdc(fcgrpcod_mdc))
                        }}
                        placeholder="GBA1"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_gba1_mdc}
                    />
                    <Select
                        size='small'
                        showSearch
                        placeholder="GBA2"
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdcgba1 && !rex_data_filter_selected_baseline.mdcfcstgroupcode){
                                let data_mdc = rex_data_mdc
                                const gba2_mdc = data_mdc.filter(mdc => mdc.mdcgba2).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba2 === mdc.mdcgba2))).map(mdc => ({label: mdc.mdcgba2, value: mdc.mdcgba2}))
                                dispatch(changeGba2Mdc(gba2_mdc))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdcgba2', 'masterdataclients')
                            changeFiltersBaseline(value, "mdcgba2")
                            let data_mdc = rex_data_mdc
                            if(rex_data_filter_selected_baseline.mdcgba1){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcgba1 === rex_data_filter_selected_baseline.mdcgba1)
                            }
                            if(rex_data_filter_selected_baseline.mdcfcstgroupcode){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcfcstgroupcode === rex_data_filter_selected_baseline.mdcfcstgroupcode)
                            }
                            if(value != undefined){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcgba2 == value)
                            }
                            const gba1_mdc = data_mdc.filter(mdc => mdc.mdcgba1).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba1 === mdc.mdcgba1))).map(mdc => ({label: mdc.mdcgba1, value: mdc.mdcgba1}))
                            const fcgrpcod_mdc = data_mdc.filter(mdc => mdc.mdcfcstgroupcode).filter((gc, index, self) => index === self.findIndex((t) => (t.mdcfcstgroupcode === gc.mdcfcstgroupcode))).map(gc => ({label: gc.mdcfcstgroupcode, value: gc.mdcfcstgroupcode}))

                            dispatch(changeGba1Mdc(gba1_mdc))
                            dispatch(changeFcGrpCodMdc(fcgrpcod_mdc))
                        }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_gba2_mdc}
                    />
                    <Select
                        size='small'
                        showSearch
                        placeholder="FC GRP COD"
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdcgba1 && !rex_data_filter_selected_baseline.mdcgba2){
                                let data_mdc = rex_data_mdc
                                const fcgrpcod_mdc = data_mdc.filter(mdc => mdc.mdcfcstgroupcode).filter((gc, index, self) => index === self.findIndex((t) => (t.mdcfcstgroupcode === gc.mdcfcstgroupcode))).map(gc => ({label: gc.mdcfcstgroupcode, value: gc.mdcfcstgroupcode}))
                                dispatch(changeFcGrpCodMdc(fcgrpcod_mdc))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdcfcstgroupcode', 'masterdataclients')
                            changeFiltersBaseline(value, "mdcfcstgroupcode")
                            let data_mdc = rex_data_mdc
                            if(rex_data_filter_selected_baseline.mdcgba1){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcgba1 === rex_data_filter_selected_baseline.mdcgba1)
                            }
                            if(rex_data_filter_selected_baseline.mdcgba2){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcgba2 === rex_data_filter_selected_baseline.mdcgba2)
                            }
                            if(value != undefined){
                                data_mdc = data_mdc.filter(mdc => mdc.mdcfcstgroupcode == value)
                            }
                            const gba1_mdc = data_mdc.filter(mdc => mdc.mdcgba1).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba1 === mdc.mdcgba1))).map(mdc => ({label: mdc.mdcgba1, value: mdc.mdcgba1}))
                            const gba2_mdc = data_mdc.filter(mdc => mdc.mdcgba2).filter((mdc, index, self) => index === self.findIndex((t) => (t.mdcgba2 === mdc.mdcgba2))).map(mdc => ({label: mdc.mdcgba2, value: mdc.mdcgba2}))

                            dispatch(changeGba1Mdc(gba1_mdc))
                            dispatch(changeGba2Mdc(gba2_mdc))
                        }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_fc_grp_cod_mdc}
                    />
                    <Select
                        showSearch
                        size='small'
                        placeholder="SECTOR"
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpcategoria && !rex_data_filter_selected_baseline.mdpsubcategoria && !rex_data_filter_selected_baseline.mdpmarca && !rex_data_filter_selected_baseline.mdpmetrajetalla && !rex_data_filter_selected_baseline.mdpformato && !rex_data_filter_selected_baseline.mdpresumen){
                                let data_mdp = rex_data_mdp
                                const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                                dispatch(changeSectorMdp(sector))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpsector', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpsector")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpcategoria === rex_data_filter_selected_baseline.mdpcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpsubcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsubcategoria === rex_data_filter_selected_baseline.mdpsubcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmarca){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmarca === rex_data_filter_selected_baseline.mdpmarca)
                            }
                            if(rex_data_filter_selected_baseline.mdpmetrajetalla){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmetrajetalla === rex_data_filter_selected_baseline.mdpmetrajetalla)
                            }
                            if(rex_data_filter_selected_baseline.mdpformato){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpformato === rex_data_filter_selected_baseline.mdpformato)
                            }
                            if(rex_data_filter_selected_baseline.mdpresumen){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpresumen === rex_data_filter_selected_baseline.mdpresumen)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpsector == value)
                            }
                            const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                            const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                            const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                            const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                            const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                            const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                            dispatch(changeCategoriaMdp(categoria))
                            dispatch(changeSubcategoriaMdp(subcategoria))
                            dispatch(changeMarcaMdp(marca))
                            dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            dispatch(changeFormatoMdp(formato))
                            dispatch(changeResumenMdp(resumen))
                        }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_sector_mdp}

                    />
                    <Select
                        showSearch
                        size='small'
                        placeholder="CATEGORIA"
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpsector && !rex_data_filter_selected_baseline.mdpsubcategoria && !rex_data_filter_selected_baseline.mdpmarca && !rex_data_filter_selected_baseline.mdpmetrajetalla && !rex_data_filter_selected_baseline.mdpformato && !rex_data_filter_selected_baseline.mdpresumen){
                                let data_mdp = rex_data_mdp
                                const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                                dispatch(changeCategoriaMdp(categoria))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpcategoria', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpcategoria")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpsector){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsector === rex_data_filter_selected_baseline.mdpsector)
                            }
                            if(rex_data_filter_selected_baseline.mdpsubcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsubcategoria === rex_data_filter_selected_baseline.mdpsubcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmarca){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmarca === rex_data_filter_selected_baseline.mdpmarca)
                            }
                            if(rex_data_filter_selected_baseline.mdpmetrajetalla){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmetrajetalla === rex_data_filter_selected_baseline.mdpmetrajetalla)
                            }
                            if(rex_data_filter_selected_baseline.mdpformato){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpformato === rex_data_filter_selected_baseline.mdpformato)
                            }
                            if(rex_data_filter_selected_baseline.mdpresumen){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpresumen === rex_data_filter_selected_baseline.mdpresumen)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpcategoria == value)
                            }
                            const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                            const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                            const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                            const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                            const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                            const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                            dispatch(changeSectorMdp(sector))
                            dispatch(changeSubcategoriaMdp(subcategoria))
                            dispatch(changeMarcaMdp(marca))
                            dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            dispatch(changeFormatoMdp(formato))
                            dispatch(changeResumenMdp(resumen))
                        }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_categoria_mdp}
                    />
                    <Select
                        showSearch
                        size='small'
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpsector && !rex_data_filter_selected_baseline.mdpcategoria && !rex_data_filter_selected_baseline.mdpmarca && !rex_data_filter_selected_baseline.mdpmetrajetalla && !rex_data_filter_selected_baseline.mdpformato && !rex_data_filter_selected_baseline.mdpresumen){
                                let data_mdp = rex_data_mdp
                                const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                                dispatch(changeSubcategoriaMdp(subcategoria))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpsubcategoria', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpsubcategoria")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpsector){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsector === rex_data_filter_selected_baseline.mdpsector)
                            }
                            if(rex_data_filter_selected_baseline.mdpcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpcategoria === rex_data_filter_selected_baseline.mdpcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmarca){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmarca === rex_data_filter_selected_baseline.mdpmarca)
                            }
                            if(rex_data_filter_selected_baseline.mdpmetrajetalla){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmetrajetalla === rex_data_filter_selected_baseline.mdpmetrajetalla)
                            }
                            if(rex_data_filter_selected_baseline.mdpformato){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpformato === rex_data_filter_selected_baseline.mdpformato)
                            }
                            if(rex_data_filter_selected_baseline.mdpresumen){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpresumen === rex_data_filter_selected_baseline.mdpresumen)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpsubcategoria == value)
                            }
                            const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                            const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                            const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                            const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                            const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                            const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                            dispatch(changeSectorMdp(sector))
                            dispatch(changeCategoriaMdp(categoria))
                            dispatch(changeMarcaMdp(marca))
                            dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            dispatch(changeFormatoMdp(formato))
                            dispatch(changeResumenMdp(resumen))
                        }}
                        placeholder="SUB-CATEGORIA"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 150,
                        }}
                        allowClear={true}
                        options={rex_subcategoria_mdp}
                    />
                    <Select
                        size='small'
                        showSearch
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpsector && !rex_data_filter_selected_baseline.mdpcategoria && !rex_data_filter_selected_baseline.mdpsubcategoria && !rex_data_filter_selected_baseline.mdpmetrajetalla && !rex_data_filter_selected_baseline.mdpformato && !rex_data_filter_selected_baseline.mdpresumen){
                                let data_mdp = rex_data_mdp
                                const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                                dispatch(changeMarcaMdp(marca))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpmarca', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpmarca")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpsector){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsector === rex_data_filter_selected_baseline.mdpsector)
                            }
                            if(rex_data_filter_selected_baseline.mdpcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpcategoria === rex_data_filter_selected_baseline.mdpcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpsubcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsubcategoria === rex_data_filter_selected_baseline.mdpsubcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmetrajetalla){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmetrajetalla === rex_data_filter_selected_baseline.mdpmetrajetalla)
                            }
                            if(rex_data_filter_selected_baseline.mdpformato){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpformato === rex_data_filter_selected_baseline.mdpformato)
                            }
                            if(rex_data_filter_selected_baseline.mdpresumen){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpresumen === rex_data_filter_selected_baseline.mdpresumen)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpmarca == value)
                            }
                            const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                            const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                            const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                            const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                            const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                            const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                            dispatch(changeSectorMdp(sector))
                            dispatch(changeCategoriaMdp(categoria))
                            dispatch(changeSubcategoriaMdp(subcategoria))
                            dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            dispatch(changeFormatoMdp(formato))
                            dispatch(changeResumenMdp(resumen))
                        }}
                        placeholder="MARCA"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_marca_mdp}
                    />
                    <Select
                        showSearch
                        size='small'
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpsector && !rex_data_filter_selected_baseline.mdpcategoria && !rex_data_filter_selected_baseline.mdpsubcategoria && !rex_data_filter_selected_baseline.mdpmarca && !rex_data_filter_selected_baseline.mdpformato && !rex_data_filter_selected_baseline.mdpresumen){
                                let data_mdp = rex_data_mdp
                                const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                                dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpmetrajetalla', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpmetrajetalla")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpsector){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsector === rex_data_filter_selected_baseline.mdpsector)
                            }
                            if(rex_data_filter_selected_baseline.mdpcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpcategoria === rex_data_filter_selected_baseline.mdpcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpsubcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsubcategoria === rex_data_filter_selected_baseline.mdpsubcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmarca){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmarca === rex_data_filter_selected_baseline.mdpmarca)
                            }
                            if(rex_data_filter_selected_baseline.mdpformato){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpformato === rex_data_filter_selected_baseline.mdpformato)
                            }
                            if(rex_data_filter_selected_baseline.mdpresumen){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpresumen === rex_data_filter_selected_baseline.mdpresumen)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpmetrajetalla == value)
                            }
                            const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                            const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                            const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                            const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                            const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                            const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                            dispatch(changeSectorMdp(sector))
                            dispatch(changeCategoriaMdp(categoria))
                            dispatch(changeSubcategoriaMdp(subcategoria))
                            dispatch(changeMarcaMdp(marca))
                            dispatch(changeFormatoMdp(formato))
                            dispatch(changeResumenMdp(resumen))
                        }}
                        placeholder="METRAJE/TALLA"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_metraje_talla_mdp}
                    />
                    <Select
                        size='small'
                        showSearch
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpsector && !rex_data_filter_selected_baseline.mdpcategoria && !rex_data_filter_selected_baseline.mdpsubcategoria && !rex_data_filter_selected_baseline.mdpmarca && !rex_data_filter_selected_baseline.mdpmetrajetalla && !rex_data_filter_selected_baseline.mdpresumen){
                                let data_mdp = rex_data_mdp
                                const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                                dispatch(changeFormatoMdp(formato))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpformato', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpformato")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpsector){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsector === rex_data_filter_selected_baseline.mdpsector)
                            }
                            if(rex_data_filter_selected_baseline.mdpcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpcategoria === rex_data_filter_selected_baseline.mdpcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpsubcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsubcategoria === rex_data_filter_selected_baseline.mdpsubcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmarca){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmarca === rex_data_filter_selected_baseline.mdpmarca)
                            }
                            if(rex_data_filter_selected_baseline.mdpmetrajetalla){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmetrajetalla === rex_data_filter_selected_baseline.mdpmetrajetalla)
                            }
                            if(rex_data_filter_selected_baseline.mdpresumen){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpresumen === rex_data_filter_selected_baseline.mdpresumen)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpformato == value)
                            }
                            const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                            const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                            const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                            const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                            const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                            const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                            dispatch(changeSectorMdp(sector))
                            dispatch(changeCategoriaMdp(categoria))
                            dispatch(changeSubcategoriaMdp(subcategoria))
                            dispatch(changeMarcaMdp(marca))
                            dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            dispatch(changeResumenMdp(resumen))
                        }}
                        placeholder="FORMATO"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_formato_mdp}
                    />
                    <Select
                        showSearch
                        size='small'
                        onClear={() => {
                            if(!rex_data_filter_selected_baseline.mdpsector && !rex_data_filter_selected_baseline.mdpcategoria && !rex_data_filter_selected_baseline.mdpsubcategoria && !rex_data_filter_selected_baseline.mdpmarca && !rex_data_filter_selected_baseline.mdpmetrajetalla && !rex_data_filter_selected_baseline.mdpformato){
                                let data_mdp = rex_data_mdp
                                const resumen = data_mdp.filter(mdp => mdp.mdpresumen).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpresumen === mdp.mdpresumen))).map(mdp => ({label: mdp.mdpresumen, value: mdp.mdpresumen}))
                                dispatch(changeResumenMdp(resumen))
                            }
                        }}
                        onChange={(value) => {
                            handleFilterDataBaseline(value, 'mdpresumen', 'masterdataproducts')
                            changeFiltersBaseline(value, "mdpresumen")
                            let data_mdp = rex_data_mdp
                            if(rex_data_filter_selected_baseline.mdpsector){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsector === rex_data_filter_selected_baseline.mdpsector)
                            }
                            if(rex_data_filter_selected_baseline.mdpcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpcategoria === rex_data_filter_selected_baseline.mdpcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpsubcategoria){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpsubcategoria === rex_data_filter_selected_baseline.mdpsubcategoria)
                            }
                            if(rex_data_filter_selected_baseline.mdpmarca){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmarca === rex_data_filter_selected_baseline.mdpmarca)
                            }
                            if(rex_data_filter_selected_baseline.mdpmetrajetalla){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpmetrajetalla === rex_data_filter_selected_baseline.mdpmetrajetalla)
                            }
                            if(rex_data_filter_selected_baseline.mdpformato){
                                data_mdp = data_mdp.filter(mdp => mdp.mdpformato === rex_data_filter_selected_baseline.mdpformato)
                            }
                            if(value != undefined){
                                data_mdp = data_mdp.filter(mdc => mdc.mdpresumen == value)
                            }
                            const sector = data_mdp.filter(mdp => mdp.mdpsector).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsector === mdp.mdpsector))).map(mdp => ({label: mdp.mdpsector, value: mdp.mdpsector}))
                            const categoria = data_mdp.filter(mdp => mdp.mdpcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpcategoria === mdp.mdpcategoria))).map(mdp => ({label: mdp.mdpcategoria, value: mdp.mdpcategoria}))
                            const subcategoria = data_mdp.filter(mdp => mdp.mdpsubcategoria).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpsubcategoria === mdp.mdpsubcategoria))).map(mdp => ({label: mdp.mdpsubcategoria, value: mdp.mdpsubcategoria}))
                            const marca = data_mdp.filter(mdp => mdp.mdpmarca).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmarca === mdp.mdpmarca))).map(mdp => ({label: mdp.mdpmarca, value: mdp.mdpmarca}))
                            const metrajeTalla = data_mdp.filter(mdp => mdp.mdpmetrajetalla).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpmetrajetalla === mdp.mdpmetrajetalla))).map(mdp => ({label: mdp.mdpmetrajetalla, value: mdp.mdpmetrajetalla}))
                            const formato = data_mdp.filter(mdp => mdp.mdpformato).filter((mdp, index, self) => index === self.findIndex((t) => (t.mdpformato === mdp.mdpformato))).map(mdp => ({label: mdp.mdpformato, value: mdp.mdpformato}))
                            dispatch(changeSectorMdp(sector))
                            dispatch(changeCategoriaMdp(categoria))
                            dispatch(changeSubcategoriaMdp(subcategoria))
                            dispatch(changeMarcaMdp(marca))
                            dispatch(changeMetrajeTallaMdp(metrajeTalla))
                            dispatch(changeFormatoMdp(formato))
                        }}
                        placeholder="RESUMEN"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                            width: 120,
                        }}
                        allowClear={true}
                        options={rex_resumen_mdp}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Filters
import config from '../../config'
import axios from 'axios'
import { addNotifications } from "../Reducers/Fileupload"
import { refreshDashboardServices } from './Baseline'

export const UploadPricingMasterServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Maestra de Precios")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/princing-master', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch(addNotifications(rex_msgs_notificaciones))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }
        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const UploadRealSellinServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Real Sell In")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/baseline-sellin', formData, {
        // const rquest = await axios.post(config.api_url+'file-upload/real-sellin', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.response ?  `El archivo se subió correctamente` : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }
                dispatch(addNotifications(notificationNew))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const UploadMasterClientsServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Master Clients")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/client-master', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                let new_notification = {
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.response ?  `El archivo se subió correctamente` : rquest.data.message,
                    "respuesta" : rquest.data.response,                    
                    "data"      : rquest.data.notificaciones,
                    "isOpen" : false
                }

                dispatch(addNotifications(new_notification))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}
export const UploadBaselineServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Baseline")  => {

    const formData = new FormData()

    let updateDashboard = false
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/forecast', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            // if(rquest.status == 200){
                let notificationNew = {
                    "titulo"    : "Notificación N°"+numero_notificacion,
                    "mensaje"   : rquest.data.response ?  `El archivo se subió correctamente` : rquest.data.message,
                    "respuesta" : rquest.data.response,
                    "data"      : rquest.data.notificaciones,
                    "isOpen"    : false
                }
                dispatch(addNotifications(notificationNew))
            // }
            updateDashboard = true
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    if(updateDashboard){
        dispatch(refreshDashboardServices(dispatch))
    }

    return rpta
}

export const UploadSellOutServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Sell Out")  => {

    const formData = new FormData()

    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/sell-out', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch(addNotifications(rex_msgs_notificaciones))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const CargaMasterSellIn = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Sell In")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/sell-in', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch(addNotifications(rex_msgs_notificaciones))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const UploadProductMasterServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Maestra de Productos")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/product-master', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){
                let newNotify = {
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                }
                dispatch(addNotifications(newNotify))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}

export const UploadProductMasterLastServices = async (dispatch, rex_msgs_notificaciones, fileList, titleFile = "Maestra de Productos Last")  => {

    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('data_file', file)
    })
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'file-upload/product-master-last', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){
                let newNotify = {
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                }
                dispatch(addNotifications(newNotify))
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)
        rpta = false
        let notificationNew = {
            "titulo"    : "Notificación N°"+numero_notificacion,
            "mensaje"   : "Ha ocurrido un error al subir el archivo",
            "respuesta" : false,
            "data"      : [],
            "isOpen"    : false
        }

        dispatch(addNotifications(notificationNew))
    }

    return rpta
}
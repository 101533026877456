import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "@tanstack/react-query"
import { Col, Row } from 'antd'
import { 
	CargaMasterSellIn, 
	UploadPricingMasterServices, 
	UploadProductMasterLastServices, 
	UploadProductMasterServices,
	UploadRealSellinServices,
	UploadSellOutServices,
	UploadMasterClientsServices,
	UploadBaselineServices
} from "../../Redux/Services/FileUpload"
import CardFileUpload from '../../Components/FileUpload/CardFileUpload'
import CardNotification from '../../Components/FileUpload/CardNotification'
import './../../Styles/Routes/FileUpload.css'

const FileUpload = () => {

	const dispatch = useDispatch()
	const { rex_msgs_notificaciones } = useSelector(state => state.fileUpload)

	const uploadPricingMaster = useMutation({
        mutationFn: (fileUrl) => UploadPricingMasterServices(dispatch, rex_msgs_notificaciones,fileUrl),
    })

	const uploadRealSellin = useMutation({
		mutationFn: (fileUrl) => UploadRealSellinServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadProductMaster = useMutation({
		mutationFn: (fileUrl) => UploadProductMasterServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadProductMasterLast = useMutation({
		mutationFn: (fileUrl) => UploadProductMasterLastServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadClientMaster = useMutation({
		mutationFn: (fileUrl) => UploadMasterClientsServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const cargaMasterSellIn = useMutation({
        mutationFn: (fileUrl) => CargaMasterSellIn(dispatch, rex_msgs_notificaciones, fileUrl),
	})

	const uploadSellOut = useMutation({
		mutationFn: (fileUrl) => UploadSellOutServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	const uploadBaseline = useMutation({
		mutationFn: (fileUrl) => UploadBaselineServices(dispatch, rex_msgs_notificaciones, fileUrl)
	})

	return (
		<Row>
			<Col span={24} style={{ margin: '15px 0' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Carga de Archivos</div>
			</Col>
			<Col span={18}>
				<Row gutter={[0, 34]}>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Baseline"}
							onAction={(fileUrl) => {
								uploadBaseline.mutate(fileUrl)							
							}}
							isLoading={uploadBaseline.isPending}
							isSuccess={uploadBaseline.isSuccess}
						/>
					</Col>
					{/* <Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Master Pricing"}
							onAction={(fileUrl) => {
								uploadPricingMaster.mutate(fileUrl)
							}}
							isLoading={uploadPricingMaster.isPending}
							isSuccess={uploadPricingMaster.isSuccess}
						/>
					</Col> */}
					{/* <Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Sell In"}
							onAction={(fileUrl) => {
								cargaMasterSellIn.mutate(fileUrl)
							}}
							isLoading={cargaMasterSellIn.isPending}
							isSuccess={cargaMasterSellIn.isSuccess}
						/>
					</Col> */}
					{/* <Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Master Data Products"}
							onAction={(fileUrl) => {
								uploadProductMaster.mutate(fileUrl)
							}}
							isLoading={uploadProductMaster.isPending}
							isSuccess={uploadProductMaster.isSuccess}
						/>
					</Col> */}
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Master Products"}
							onAction={(fileUrl) => {
								uploadProductMasterLast.mutate(fileUrl)
							}}
							isLoading={uploadProductMasterLast.isPending}
							isSuccess={uploadProductMasterLast.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Master Clients"}
							onAction={(fileUrl) => {
								uploadClientMaster.mutate(fileUrl)
							}}
							isLoading={uploadClientMaster.isPending}
							isSuccess={uploadClientMaster.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Sell In"}
							onAction={(fileUrl) => {
								uploadRealSellin.mutate(fileUrl)
							}}
							isLoading={uploadRealSellin.isPending}
							isSuccess={uploadRealSellin.isSuccess}
						/>
					</Col>
					<Col span={6} className='ContainerCardFileUpload'>
						<CardFileUpload
							nameFile={"Sell Out"}
							onAction={(fileUrl) => {
								uploadSellOut.mutate(fileUrl)								
							}}
							isLoading={uploadSellOut.isPending}
							isSuccess={uploadSellOut.isSuccess}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={6}>
				<CardNotification />
			</Col>
		</Row>
	)
}

export default FileUpload